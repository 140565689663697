import React from "react";
import { NavLink } from "react-router-dom";

import PageHeadline from "../global/PageHeadline";
import NewsCard from "../global/NewsCard";

const News = ({ noLink, heading, headingClassNames, headlineClassNames, description, collection, className }) => {
  return (
    <PageHeadline
      headline={heading}
      headingClassNames={headingClassNames}
      headlineClassNames={headlineClassNames}
      description={description}
      className={className || ""}
      noBar
    >
      {collection?.length ? (
        <div className="flex flex-col lg:flex-row gap-8 justify-center items-center py-12 flex-wrap">
          {collection.map((news, newsIndex) => (
            <NewsCard key={newsIndex} news={news} />
          ))}
        </div>
      ) : null}
      {!noLink ? (
        <div className="flex items-center justify-center">
          <NavLink
            to={`/newsroom`}
            className={`w-fit px-8 py-2 border-2 rounded-full hover:bg-white text-white border-navy bg-navy hover:text-navy with-transition font-semibold mb-8`}
          >
            See More
          </NavLink>
        </div>
      ) : null}
    </PageHeadline>
  );
};

export default News;
