import React from "react";
import { managers, newsList, values } from "../data/data";

import PageHeadline from "../components/global/PageHeadline";
import FoundingStory from "../components/story/FoundingStory";
import MissionAndVision from "../components/story/MissionAndVision MissionAndVision";
import Values from "../components/story/Values";
import News from "../components/news/News";

const OurStoryScreen = () => {
  return (
    <div className="container pt-[140px]">
      <PageHeadline
        headline="Our Story"
        description={[
          "“There’s no point in starting a business unless you’re going to make a dramatic difference to other people’s lives. So if you’ve got an idea that’s gonna make a big difference to other people’s lives, then just get on and do it.”",
          "― Richard Branson",
          "Welcome to Forager International, where innovation meets sustainability in the world of tourism, arts, culture, and heritage (TACH). We are a collective of passionate individuals dedicated to making a meaningful impact on the communities we serve. Our journey is rooted in a profound belief that positive change begins with action.",
        ]}
      />
      <div className="flex flex-col gap-[100px] lg:gap-[140px] py-[100px] lg:py-[140px]">
        <FoundingStory />
        <MissionAndVision />
        <Values values={values} heading="Core Values" />
        <PageHeadline headline="Team & Culture" noBar>
          <p className="text-center py-4">
            Meet the driving force behind Forager International
          </p>
          <Values
            values={managers.map((manager) => ({
              ...manager,
              format: "png",
              type: "manager",
            }))}
            listClassName="flex-col"
          />
          <p className="text-left lg:text-center lg:w-[75%] lg:mx-auto py-8">
            Our company culture is rooted in innovation, collaboration, and a
            commitment to personal growth. We prioritize work-life balance,
            fostering an environment where every team member's voice is heard
            and valued.
          </p>
        </PageHeadline>
        <News
          noLink
          heading="Impact & Achievements"
          description={[
            "If our story resonates with you, we invite you to join us on our journey. Get in touch to discover how you can contribute to the future of Forager International and the TACH sector",
            "Together, let's forage a brighter tomorrow.",
          ]}
          collection={newsList}
        />
      </div>
    </div>
  );
};

export default OurStoryScreen;
