import React from "react";

const MemberCard = ({ member, onClick }) => {
  return (
    <div
      className="member-card flex flex-col gap-8 p-6 pb-8 with-transition rounded-[40px] w-full lg:w-[24%] grow cursor-pointer hover:bg-white"
      onClick={() => onClick(member)}
    >
      <div
        className="overflow-hidden flex justify-center items-center w-full aspect-square rounded-[30px] visual bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${require(`../../assets/img/team/leadership/${member.title
            .toLowerCase()
            .split(" ")
            .join("-")}.png`)})`,
        }}
      ></div>
      <div className="flex flex-col font-semibold justify-center items-center text-center text-lg gap-2">
        <h4 className="text-navy with-transition">{member.title}</h4>
        <div className="flex flex-col items-center justify-center">
          <p className="with-transition">{member.position}</p>
          <p className="with-transition">{member.brand}</p>
        </div>
      </div>
    </div>
  );
};

export default MemberCard;
