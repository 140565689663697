const descriptions = [
  {
    pathname: "/",
    title:
      "Forager International | Innovating Solutions for the Heritage Sector",
    content: [
      "Discover Forager International, a management company dedicated to developing and running programs that support the tourism, arts, culture, and heritage sectors. We collaborate globally to create sustainable solutions for preserving and promoting natural and cultural heritage.",
    ],
    keywords: [
      "Forager International",
      "Heritage Sector Solutions",
      "TACH Sector Support",
      "Tourism Arts Culture Heritage",
      "Innovative Heritage Solutions",
      "Heritage Sector Collaboration",
      "Sustainable Heritage Management",
      "Heritage Capacity Building",
      "Heritage Sector Funding",
      "Cultural Heritage Preservation",
      "Heritage Partnerships",
      "Heritage Sector Innovation",
      "Forager Partnership Network",
      "Natural Heritage Support",
      "Cultural Heritage Support",
    ],
  },
  {
    pathname: "/our-community",
    title:
      "Forager International Community | Empowering Tourism, Arts, Culture, and Heritage",
    content: [
      "Join the Forager International community and empower your organization in the tourism, arts, culture, and heritage sectors. Our Partnership Program offers exclusive resources, support services, and revenue-sharing opportunities for both for-profit and non-profit organizations.",
    ],
    keywords: [
      "Forager International Community",
      "Tourism Arts Culture Heritage",
      "TACH Empowerment",
      "Heritage Sector Support",
      "Forager Partnership Program",
      "Exclusive Resources for TACH",
      "Heritage Sector Collaboration",
      "TACH Organizations Support",
      "For-Profit Heritage Partnerships",
      "Non-Profit Heritage Support",
      "Skills Sharing TACH",
      "Heritage Sector Operations",
      "Strategic Partners TACH",
      "Revenue Sharing Heritage",
      "Heritage Sector Growth",
    ],
  },
  {
    pathname: "/our-story",
    title: "Our Story | Forager International: Innovating the TACH Sector",
    content: [
      "Discover the journey of Forager International, where innovation and sustainability drive our mission in the Tourism, Arts, Culture, and Heritage sectors. Learn about our founding story, mission, vision, and core values that guide us in making a meaningful impact on the communities we serve.",
    ],
    keywords: [
      "Forager International Story",
      "Forager Founding History",
      "Bryce Mathew Watts Founder",
      "TACH Sector Innovation",
      "Sustainable Growth Heritage",
      "Collaborative Alliances Tourism",
      "VR Voyage Initiative",
      "Community Impact",
      "Cultural Heritage Support",
      "Positive Change Heritage Sector",
      "Forager Mission and Vision",
      "Tourism Arts Culture Heritage",
      "Mutual Respect Forager Values",
      "Adaptability in Business",
      "Partnership and Collaboration TACH",
    ],
  },
  {
    pathname: "/our-focus",
    title:
      "Forager International | Specialized Support Across Diverse Industries",
    content: [
      "Discover Forager International's expertise in supporting the tourism, arts, culture, and heritage sectors. From accounting and creative services to education, mixed reality, technology, and travel & tourism, our brands offer tailored solutions to meet the unique needs of our partners.",
    ],
    keywords: [
      "Forager International Focus",
      "Tourism, Arts, Culture, Heritage Support",
      "Diverse Industry Solutions",
      "Accounting for Tourism, Arts, Culture, Heritage",
      "Creative Services for Tourism, Arts, Culture, Heritage",
      "Educational Program Development",
      "Mixed Reality Experiences",
      "Technology Adoption for Tourism, Arts, Culture, Heritage",
      "Travel and Tourism Innovation",
      "Forager Partnership Program",
      "Financial Solutions Heritage",
      "Branding and Marketing for Tourism, Arts, Culture, Heritage",
      "Online and Digital Learning",
      "Virtual Reality Projects",
    ],
  },
  {
    pathname: "/our-team",
    title: "Forager International | Diverse Team for Innovative Solutions",
    content: [
      "Meet the diverse and culturally rich team at Forager International. Inspired by the words of Wade Davis, we believe that our team's varied backgrounds and perspectives enable us to address challenges and innovate within the tourism, arts, culture, and heritage sectors.",
    ],
    keywords: [
      "Forager International Team",
      "Diverse Team TACH",
      "Cultural Diversity in Business",
      "Wade Davis Inspiration",
      "Innovative Solutions Heritage Sector",
      "Multicultural Team Forager",
      "Diverse Perspectives Business",
      "Heritage Sector Professionals",
      "Global Team Forager",
      "Innovative Heritage Solutions",
      "Tourism Arts Culture Team",
      "Cultural Representation Forager",
      "Inclusive Business Team",
      "Diverse Workforce Innovation",
      "Forager Brand Team",
    ],
  },
  {
    pathname: "/brands/domain/main",
    title:
      "Our Brands | Forager International: Innovative Solutions for the TACH Sector",
    content: [
      "Explore the diverse brands of Forager International, dedicated to providing innovative solutions for the tourism, arts, culture, and heritage (TACH) sector. Learn how our partnerships and investments drive community and economic development globally.",
    ],
    keywords: [
      "Forager International Brands",
      "TACH Sector Solutions",
      "Tourism Arts Culture Heritage Brands",
      "Innovative Business Partnerships",
      "Economic Development TACH",
      "Community Development TACH Sector",
      "Forager Global Investments",
      "Cultural Heritage Initiatives",
      "Sustainable Tourism Solutions",
      "Forager Business Growth",
      "Creative Heritage Solutions",
      "Forager Sector Innovation",
      "Global Heritage Support",
      "Arts and Culture Investments",
      "Heritage Sector Development",
    ],
    categories: [
      {
        pathname: "/brands/domain/accounting",
        title:
          "Empower Financial Stability: Explore Forager's Accounting Solutions",
        content: [
          "Discover Forager's tailored accounting services designed to ensure financial integrity and stability for partners in the TACH sector. From bookkeeping to tax preparation, our expertise supports your organization's growth and success.",
        ],
        keywords: [
          "Forager Accounting solutions",
          "Financial stability services",
          "TACH sector bookkeeping",
          "Tax preparation for TACH",
          "Financial integrity support",
          "Accounting services for heritage",
          "Forager financial solutions",
          "TACH sector financial management",
          "Budgeting for cultural organizations",
          "Fiscal management for heritage sites",
        ],
      },
      {
        pathname: "/brands/domain/creative",
        title: "Elevate Your Brand: Forager's Creative Solutions for TACH",
        content: [
          "Explore Forager's creative branding, marketing, and design services tailored to elevate your brand's presence in the tourism, arts, culture, and heritage (TACH) sector. Let us help you stand out and make a lasting impact.",
        ],
        keywords: [
          "Forager Creative solutions",
          "Branding for TACH sector",
          "Marketing for cultural organizations",
          "Design services for heritage",
          "TACH sector brand enhancement",
          "Creative solutions for arts",
          "Forager marketing strategies",
          "Cultural branding services",
          "TACH sector design agency",
          "Heritage marketing solutions",
        ],
      },
      {
        pathname: "/brands/domain/education",
        title:
          "Innovative Learning Experiences: Forager's Educational Program Development",
        content: [
          "Discover Forager's educational program development services focused on creating innovative learning experiences for the TACH sector. From digital platforms to immersive kits, we empower lifelong learning for all ages.",
        ],
        keywords: [
          "Forager Education programs",
          "Educational experiences for TACH",
          "Learning kits for heritage",
          "Digital platforms for education",
          "Lifelong learning solutions",
          "Forager curriculum development",
          "TACH sector educational tools",
          "Heritage learning initiatives",
          "Educational program management",
          "Forager e-learning solutions",
        ],
      },
      {
        pathname: "/brands/domain/mixed-reality",
        title:
          "Immersive Experiences Redefined: Forager's Mixed Reality Solutions",
        content: [
          "Dive into Forager's mixed reality solutions, blending virtual and augmented experiences to revolutionize engagement in the tourism, arts, culture, and heritage (TACH) sector. Explore new dimensions of storytelling and exploration.",
        ],
        keywords: [
          "Forager Mixed Reality solutions",
          "Immersive experiences for TACH",
          "Virtual reality for cultural sites",
          "Augmented reality for heritage",
          "TACH sector interactive experiences",
          "Forager VR and AR solutions",
          "Cultural heritage virtual tours",
          "Heritage site immersive storytelling",
          "Mixed reality engagement",
          "Forager experiential technology",
        ],
      },
      {
        pathname: "/brands/domain/technology",
        title:
          "Innovate with Confidence: Forager's Technology Solutions for TACH",
        content: [
          "Unlock innovation with Forager's technology solutions tailored to streamline operations and enhance experiences in the tourism, arts, culture, and heritage (TACH) sector. Embrace digital transformation with confidence.",
        ],
        keywords: [
          "Forager Technology solutions",
          "Digital transformation for TACH",
          "Technology for cultural organizations",
          "Tech solutions for heritage sites",
          "TACH sector operational efficiency",
          "Forager IT solutions",
          "Cultural sector technology services",
          "Heritage site digital tools",
          "Forager tech innovation",
          "Digital solutions for arts",
        ],
      },
      {
        pathname: "/brands/domain/travel-&-tourism",
        title:
          "Explore New Horizons: Forager's Innovative Travel & Tourism Services",
        content: [
          "Embark on unforgettable journeys with Forager's innovative travel and tourism services, blending online, in-person, and digital experiences for the ultimate exploration of destinations in the tourism, arts, culture, and heritage (TACH) sector.",
        ],
        keywords: [
          "Forager Travel & Tourism services",
          "Destination exploration for TACH",
          "Innovative tourism experiences",
          "Digital travel solutions",
          "TACH sector destination marketing",
          "Forager travel experiences",
          "Cultural tourism services",
          "Heritage site travel adventures",
          "Forager destination discovery",
          "Travel & tourism innovation",
        ],
      },
    ],
    children: [
      {
        pathname: "/brands/fmg",
        title: "Forager Media Group Canada | Leading Digital Services Provider",
        content: [
          "Discover Forager Media Group Canada, a leading provider of digital services specializing in the tourism, arts, culture, and heritage (TACH) sector. Explore our innovative projects and deep partnerships contributing to creative industry growth across Canada.",
        ],
        keywords: [
          "Forager Media Group Canada",
          "Leading Digital Services Provider",
          "TACH Sector Projects",
          "Creative Industry Growth",
          "Forager Media Group Partnerships",
          "Canadian Creative Solutions",
          "Innovative Change TACH",
          "Creative Entrepreneurs Canada",
          "Forager Partnership Program",
          "Federal Support Creative Projects",
          "Provincial Recognition Innovation",
          "Regional Collaborations TACH",
          "Digital Services for Heritage",
          "Creative Industry Innovation",
          "Forager Media Group Brands",
        ],
      },
      {
        pathname: "/brands/fdi",
        title:
          "Forager Digital Canada | Transforming TACH Sector with Digital Innovation",
        content: [
          "Experience the digital revolution with Forager Digital Canada, a pioneer in graphic design, branding, UX design, and digital marketing solutions. Discover how we redefine excellence in the tourism, arts, culture, and heritage (TACH) sector.",
        ],
        keywords: [
          "Forager Digital Canada",
          "Digital Innovation Tourism Arts Culture Heritage",
          "Graphic Design Solutions",
          "Branding Excellence",
          "UX Design Specialists",
          "Digital Marketing Strategies",
          "TACH Sector Online Presence",
          "Creative Digital Solutions",
          "Forager Digital Partnerships",
          "Digital Transformation for Tourism, Arts, Culture, and Heritage",
          "Excellence Standards for Tourism, Arts, Culture, and Heritage",
          "Canadian Digital Services",
          "Forager Digital Expertise",
          "TACH Sector Digital Impact",
          "Forager Digital Brands",
        ],
      },
      {
        pathname: "/brands/fvi",
        title:
          "Forager Virtual Canada | Shaping the Future of Exploration and Learning",
        content: [
          "Embark on a journey of immersive experiences with Forager Virtual Canada, pioneers in virtual tours, educational experiences, and augmented reality (AR) innovations. Explore how we redefine storytelling and exploration in the tourism, arts, culture, and heritage (TACH) sector.",
        ],
        keywords: [
          "Forager Virtual Canada",
          "Exploration and Learning Innovations",
          "Virtual Tours for Tourism, Arts, Culture, and Heritage Sectors",
          "Educational Experiences for Tourism, Arts, Culture, and Heritage",
          "Augmented Reality Innovations",
          "Digital Self-Guided Tours",
          "Immersive Storytelling Experiences",
          "Tourism, Arts, Culture, and Heritage Sectors Engagement",
          "Forager Virtual Partnerships",
          "Mixed Reality Experiences",
          "Cultural Heritage Exploration",
          "Educational Content Transformation",
          "Forager Virtual Impact",
          "Exploration and Learning Evolution",
          "Forager Virtual Brands",
        ],
      },
      {
        pathname: "/brands/fpd",
        title:
          "Forager Productions Canada | Shaping the Future of Storytelling and Creativity",
        content: [
          "Dive into captivating narratives with Forager Productions Canada, specialists in audio and video productions for the tourism, arts, culture, and heritage (TACH) sector. Explore how we elevate stories, strengthen connections, and foster appreciation for cultural heritage through creativity.",
        ],
        keywords: [
          "Forager Productions Canada",
          "Storytelling and Creativity Innovations",
          "Audio and Video Productions for Tourism, Arts, Culture, and Heritage Sectors",
          "Captivating Narratives for Tourism, Arts, Culture, and Heritage",
          "Cultural Heritage Appreciation",
          "Arts and Culture Narratives",
          "Forager Productions Partnerships",
          "Community Connections Strengthening",
          "TACH Sector Storytelling",
          "Creative Productions Impact",
          "Narrative Enhancement for Tourism, Arts, Culture, and Heritage",
          "Forager Productions Brands",
          "Creative Storytelling Evolution",
          "Captivating Audio and Video",
          "Forager Productions Impact",
        ],
      },
      {
        pathname: "/brands/fpb",
        title:
          "Forager Publishing Canada | Nurturing Ideas and Inspiring Minds in the TACH Sector",
        content: [
          "Discover literary excellence with Forager Publishing Canada, specialists in magazine and book publishing, and digital content development for the tourism, arts, culture, and heritage (TACH) sector. Explore our diverse range of publications and digital initiatives that showcase stories, achievements, and foster a deeper sense of connection within communities.",
        ],
        keywords: [
          "Forager Publishing Canada",
          "Literary Excellence for Tourism, Arts, Culture, and Heritage Sectors",
          "Magazine and Book Publishing",
          "Digital Content Development for Tourism, Arts, Culture, and Heritage",
          "Cultural Heritage Publications",
          "Community Connection Forager Publishing",
          "Tourism, Arts, Culture, and Heritage Sectors Literary Initiatives",
          "Forager Publishing Collaborations",
          "Publishing Brand Canada",
          "Digital Initiatives for Tourism, Arts, Culture, and Heritage Sectors",
          "Literary Achievements for Tourism, Arts, Culture, and Heritage",
          "Forager Publishing Impact",
          "Natural History Publications",
          "Literary Excellence Canada",
          "Forager Publishing Partnerships",
        ],
      },
      {
        pathname: "/brands/fed",
        title:
          "Forager Education Canada | Award-Winning Educational Innovation for the TACH Sector",
        content: [
          "Explore award-winning educational innovation with Forager Education Canada, specializing in educational program development, learning kit creation, and platform management for the tourism, arts, culture, and heritage (TACH) sector. Discover how we empower individuals to explore, discover, and connect with the world around them through transformative educational experiences.",
        ],
        keywords: [
          "Forager Education Canada",
          "Educational Innovation TACH Sector",
          "Program Development TACH",
          "Learning Kit Creation Canada",
          "Educational Platform Management",
          "Award-Winning Education Initiatives",
          "TACH Sector Educational Solutions",
          "Forager Education Transformative Experiences",
          "Lifelong Learning Initiatives",
          "Educational Impact Awards",
          "Educational Empowerment TACH",
          "Forager Education Partnerships",
          "Educational Excellence Canada",
          "Educational Innovation Award",
          "Forager Education Community Impact",
        ],
      },
      {
        pathname: "/brands/ffs",
        title:
          "Forager Financial Services Canada | Trusted Outsourced Accounting Solutions for the TACH Sector",
        content: [
          "Forager Financial Services in Canada offers tailored outsourced accounting solutions for the tourism, arts, culture, and heritage (TACH) sector. With expertise in bookkeeping, financial reporting, payroll, and tax preparation, we empower organizations to thrive financially, make informed decisions, and achieve their strategic objectives. Partner with us for financial excellence and make a lasting impact in your community.",
        ],
        keywords: [
          "Forager Financial Services Canada",
          "Outsourced Accounting Solutions for Tourism, Arts, Culture, and Heritage Sector",
          "Tailored Accounting Services Canada",
          "Financial Integrity for Tourism, Arts, Culture, and Heritage Organizations",
          "Bookkeeping Expertise Canada",
          "Financial Reporting Services for Tourism, Arts, Culture, and Heritage",
          "Payroll Management Canada",
          "Tax Preparation Solutions for Tourism, Arts, Culture, and Heritage",
          "Financial Insights for Tourism, Arts, Culture, and Heritage Partners",
          "Strategic Financial Guidance Canada",
          "Trusted Accounting Partners Canada",
          "Financial Excellence Forager Financial Services",
          "Tourism, Arts, Culture, and Heritage Sector Financial Stability",
          "Financial Management Solutions Canada",
          "Accounting Services for Nonprofits Canada",
        ],
      },
      {
        pathname: "/brands/vrc",
        title:
          "Classroom Voyager BC | Empowering Educational Innovation in British Columbia",
        content: [
          "Classroom Voyager is a leading educational platform in British Columbia. Developed in partnership with the BC Museums Association and Forager Education (Canada), it connects arts, culture, and heritage institutions with schools and educators across BC, amplifying their reach and impact. Explore how Classroom Voyager empowers the TACH sector, inspires learners, and preserves cultural heritage in BC.",
        ],
        keywords: [
          "Classroom Voyager BC",
          "Educational Innovation Platform BC",
          "BC Museums Association Partnership",
          "Forager Education Canada Collaboration",
          "Educational Resources BC",
          "Cultural Heritage Programs BC",
          "Classroom Resource Marketplace BC",
          "British Columbia School System",
          "Impactful Educational Initiatives",
          "Community Engagement BC",
          "Next Generation Learning BC",
          "BC Cultural Heritage Preservation",
          "Educational Outreach British Columbia",
          "School Curriculum Integration BC",
          "Tourism, Arts, Culture, and Heritage Sectors Empowerment",
        ],
      },
      {
        pathname: "/brands/vrv",
        title:
          "Immerse Yourself in The VR Voyager | Explore New Destinations Digitally",
        content: [
          "Experience the future of tourism with The VR Voyage, a groundbreaking platform blending virtual, digital, and in-person experiences. Explore destinations through the lens of tourism, arts, culture, and heritage organizations like never before. Discover how The VR Voyage is redefining travel and cultural exchange with innovative storytelling and cutting-edge technology.",
        ],
        keywords: [
          "The VR Voyager",
          "Virtual Tourism Experiences",
          "Digital Destination Exploration",
          "Immersive Travel Platform",
          "Cultural Exchange Initiatives",
          "Tourism Innovation Technology",
          "Destination Discovery VR",
          "Award-Winning Tourism Platform",
          "Global Impactful Travel Experiences",
          "Virtual Reality Tourism",
          "Digital Cultural Exploration",
          "Innovative Travel Experiences",
          "Immersive Cultural Journeys",
          "Next-Generation Destination Discovery",
        ],
      },
      {
        pathname: "/brands/fnd",
        title:
          "Preserve and Protect: Forager Foundation's Commitment to Heritage Conservation",
        content: [
          "Forager Foundation is dedicated to preserving and promoting the world's natural and cultural heritage. Explore our initiatives aimed at safeguarding invaluable landmarks and managing at-risk heritage sites for future generations. Join us in our mission to make a lasting impact through conservation, education, and community engagement.",
        ],
        keywords: [
          "Forager Foundation",
          "Heritage Conservation",
          "Cultural Preservation Initiatives",
          "Natural Heritage Protection",
          "Conservation Programs",
          "At-Risk Heritage Sites Management",
          "Yale Historic Site Management",
          "Point Ellice House Museum Management",
          "Heritage Site Preservation",
          "Parks Canada Partnership",
          "English Heritage Collaboration",
          "Community Engagement Initiatives",
          "Heritage Site Sustainability",
          "Conservation Volunteer Opportunities",
          "Donate to Heritage Preservation",
        ],
      },
    ],
  },
  {
    pathname: "/newsroom",
    title: "Stay Updated with Forager: Latest News and Announcements",
    content: [
      "Discover the latest developments and announcements from Forager International and its network of companies. Stay informed about our innovative projects and initiatives shaping the TACH sector (tourism, arts, culture, and heritage). Join us on our journey of exploration and innovation.",
    ],
    keywords: [
      "Forager News",
      "Latest Updates",
      "Forager International Announcements",
      "Tourism, Arts, Culture, and Heritage Sectors Innovation",
      "Tourism Industry News",
      "Arts and Culture Updates",
      "Heritage Preservation Initiatives",
      "Network Expansion",
      "Partner Relationships",
      "Company Growth",
      "Industry Developments",
      "Innovation in Heritage Sector",
      "Exploration Initiatives",
      "Tourism, Arts, Culture, and Heritage Sectors Partnerships",
      "Collaborative Projects",
    ],
    children: [
      {
        pathname: "/newsroom/the-vr-voyage-is-a-finalist",
        title:
          "The VR Voyage: Finalist in Aceler@Tech In Portugal Startup Competition",
        content: [
          "Exciting news! The VR Voyage has been selected as a finalist in the prestigious Aceler@Tech In Portugal Startup Competition. Learn more about our innovative tourism platform and its recognition on the global stage.",
        ],
        keywords: [
          "The VR Voyage",
          "Aceler@Tech In Portugal",
          "Tourism Startup Competition",
          "Innovation in Tourism",
          "Virtual Tourism Platform",
          "Immersive Travel Experiences",
          "Global Recognition",
          "Travel Technology",
          "Cultural Heritage Exploration",
          "Finalist Announcement",
          "Tourism Innovation Awards",
          "Travel Tech Competition",
          "Startup Growth Potential",
          "International Recognition",
          "Tourism Industry Disruption",
        ],
      },
    ],
  },
  {
    pathname: "/careers",
    title: "Join Our Inclusive Team: Explore Careers at Forager",
    content: [
      "At Forager, we prioritize diversity, inclusion, and remote work flexibility. Discover rewarding career opportunities where your skills are valued, and your potential is recognized. Explore our open positions and join us in shaping the future of the Tourism, Arts, Culture, and Heritage sectors.",
    ],
    keywords: [
      "Forager job opportunities",
      "Inclusive workplace culture",
      "Remote work flexibility",
      "Career development at Forager",
      "Diversity in employment",
      "Skill-sharing opportunities",
      "Remote job openings",
      "Inclusive hiring practices",
      "Career growth at Forager",
      "Representativeness in teams",
      "Work-life balance at Forager",
      "Remote work opportunities",
      "Explore Forager careers",
      "Join our diverse team",
      "Inclusive recruitment process",
    ],
  },
  {
    pathname: "/contact",
    title: "Connect with Forager International: Reach Out Today!",
    content: [
      "Have questions about Forager International or our managed brands? Interested in partnership opportunities? Get in touch with our dedicated team today. Use the form below to send us a message, and we'll respond promptly to address your inquiries.",
    ],
    keywords: [
      "Forager International contact",
      "Partnership inquiries",
      "Get in touch with Forager",
      "Contact us for questions",
      "Reach out to Forager",
      "Inquiry form for Forager",
      "Connect with Forager team",
      "Partner with Forager International",
      "Contact information for Forager",
      "Message Forager team",
      "Consultation request for Forager",
      "Contact form for Forager International",
      "Forager International partnerships",
      "Reach out to Forager International",
      "Inquiry submission for Forager",
    ],
  },
];

export default descriptions;
