import React from "react";

import { newsList } from "../data/data";

import PageHeadline from "../components/global/PageHeadline";
import News from "../components/news/News";

const NewsroomScreen = () => {
  return (
    <div className="container py-[140px]">
      <PageHeadline
        headline="Exciting Forager News"
        subheading="Explore The Updates Coming From Across Our Network"
        description={[
          "Forager International is growing quickly and there are new things in development across our network all the time. This is the place to explore the latest announcements and updates to our companies. Whenever something new comes along that is an important update that showcases how our network of companies is growing and strengthening the relationships we have with our partners in the TACH sector (tourism, arts, culture, and heritage), we will be publishing it here.",
          "Join us on this journey of innovation for the TACH sector, heritage, and exploration.",
        ]}
      />
      <div className="pt-[50px]">
        <News noLink collection={newsList} />
      </div>
    </div>
  );
};

export default NewsroomScreen;
