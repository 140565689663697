export const redirect = () => {
  if (!window.location.host.includes("localhost")) {
    if (!window.location.protocol.includes("s")) {
      window.location.replace(
        `https://${window.location.host}${
          window.location.pathname.length > 1
            ? `/${window.location.pathname}`
            : ""
        }`,
      );
    }
  }
};

export const block = () => {
  document.onkeydown = function (e) {
    if (e.keyCode === 123) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === "I".charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === "J".charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.keyCode === "U".charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === "C".charCodeAt(0)) {
      return false;
    }
  };

  document.oncontextmenu = function (e) {
    if (e.button === 2) {
      e.preventDefault();
    }
    return true;
  };
};

export const generateSlug = (text) => {
  return text
    .replaceAll(/[^a-zA-Z_ ]/g, "")
    .trim()
    .toLowerCase()
    .split(" ")
    .join("-");
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const validatePassword = (password) => {
  return String(password).match(
    /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&? "]).*$/,
  );
};

export const passwordRules = [
  { rule: /.{8,}/, text: "It must be at least 8-characters long." },
  {
    rule: /(?=.*?[0-9])/,
    text: "It must contain at least one digit.",
  },
  {
    rule: /(?=.*?[#?!@$%^&*-])/,
    text: "It must contain at least one special character.",
  },
  {
    rule: /(?=.*?[A-Z])/,
    text: "It must contain at least one upper case character.",
  },
  {
    rule: /(?=.*?[a-z])/,
    text: "It must contain at least one lower case character.",
  },
];

export const emailValidateErrorMessage = "Invalid email format!";

export const passwordValidateErrorMessage =
  "Password must be at least 8-characters long and has at least one uppercase letter, one lowercase letter, and one special character!";

export const matchPasswords = (password, confirmation) => {
  return password.match(confirmation);
};

export const passwordMatchingErrorMessage = "Passwords don't match!";

export const validateRequiredFields = (stringArray) => {
  stringArray.forEach((element) => {
    if (element.length === 0) {
      return false;
    }
  });

  return true;
};

export const requiredFieldsErrorMessage =
  "All fields marked with an an ( * ) are required!";

export const requiredVisualsErrorMessage = "All visuals are required!";

export const requiredTagsErrorMessage = "Please select at least one tag!";

export const requiredDaysErrorMessage =
  "Please select at least one working day!";

export const validateCoordinate = (coordinate) => {
  return /^-?\d+\.?\d*$/.test(coordinate);
};

export const invalidCoordinatesErrorMessage = "Invalid location coordinates!";

export const slugify = (text) => text.toLowerCase().split(" ").join("-");
