import React from "react";
import { Link } from "react-router-dom";

const ForagerGivesBack = ({ className, handsColor = "navy", text, cta }) => {
  return (
    <article
      className={`bg-denim rounded-br-[30px] text-white mb-24 lg:mb-36 relative lg:grid lg:grid-cols-12 rounded-[30px] overflow-hidden ${
        className || ""
      }`}
    >
      <div
        className="lg:col-span-5 2xl:col-span-3 bg-contain bg-left-top bg-no-repeat h-52 md:h-72 lg:w-[130%] xl:h-[350px] xl:w-[150%] 2xl:w-[130%] 2xl:h-full"
        style={{
          backgroundImage: `url(${require(`../../assets/img/home/${handsColor}-hands.png`)})`,
        }}
      />
      <div className="col-span-7 2xl:col-span-9 pb-16 pt-8 lg:py-[100px] px-8">
        <span className="h-0.5 w-20 rounded-full bg-white block mb-1" />
        <h3 className="font-bold mb-8">Forager Gives Back</h3>
        {text?.length ? (
          <div className="mb-5 flex flex-col gap-4">
            {text.map((paragraph, paragraphIndex) => (
              <p key={paragraphIndex}>
                {paragraph}
                {cta ? (
                  <>
                    {paragraphIndex === text.length - 1 ? (
                      <Link
                        to={cta.to}
                        className="font-semibold hover:text-cyan with-transition"
                      >
                        {cta.text}
                      </Link>
                    ) : null}
                  </>
                ) : null}
              </p>
            ))}
          </div>
        ) : null}
      </div>
    </article>
  );
};

export default ForagerGivesBack;
