import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { slugify } from "../constants/helpers";

import { newsList } from "../data/data";

import BlogHead from "../components/blogs/BlogHead";
import BlogBody from "../components/blogs/BlogBody";
// import BlogCTA from "../components/blogs/BlogCTA";

const SingleNewsroomScreen = () => {
  const { slug } = useParams();

  const [news, setNews] = useState(null);

  useEffect(() => {
    if (slug) {
      setNews(newsList.find((news) => slugify(news.heading) === slug));
    }
  }, [slug]);

  return news ? (
    <>
      <div className="container py-[140px]">
        <BlogHead blog={news} />
        <BlogBody blog={news} />
        {/* <BlogCTA /> */}
      </div>
    </>
  ) : null;
};

export default SingleNewsroomScreen;
