import React from "react";
import { Link } from "react-router-dom";

const ChildBrandCard = ({ brand }) => {
  return (
    <div className="bg-navy text-white px-8 py-12 rounded-xl w-full lg:h-[520px] lg:w-[22%] grow flex flex-col gap-12 items-center justify-center lg:justify-start text-white">
      <img
        src={require(`../../assets/logos/brands-nomargin-white/${brand.slug}.svg`)}
        alt={`brand-${brand.heading}`}
        className="h-[50px]"
      />
      <p className="lg:grow">
        {brand.description}...{" "}
        <Link
          to={`/brands/${brand.slug}`}
          className="font-bold hover:text-cyan with-transition"
        >
          Learn More
        </Link>
      </p>
      {/* <Link
        to={`/brands/${brand.slug}`}
        className="w-fit inline-flex font-bold flex gap-1 hover:gap-4 with-transition justify-self-end"
      >
        <span>Learn More</span>
        <i
          className="iconify my-auto"
          data-icon="ep:arrow-up-bold"
          data-rotate="90deg"
        />
      </Link> */}
    </div>
  );
};

export default ChildBrandCard;
