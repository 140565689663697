import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { userCreateReducer, userLoginReducer } from "./reducers/userReducers";

import {
  contactMailCreateReducer,
  contactMailListReducer,
  contactMailDeleteReducer,
  contactMailAuthReducer,
} from "./reducers/contactMailReducers";

//
//
//
const reducer = combineReducers({
  userCreate: userCreateReducer,
  userLogin: userLoginReducer,
  //
  contactMailCreate: contactMailCreateReducer,
  contactMailList: contactMailListReducer,
  contactMailDelete: contactMailDeleteReducer,
  contactMailAuth: contactMailAuthReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
