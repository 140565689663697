import React from "react";

import BlogReadingTime from "./BlogReadingTime";

const BlogHead = ({ blog }) => {
  return blog ? (
    <div className="grid lg:grid-cols-2 items-center gap-8 mb-14">
      <div>
        <BlogReadingTime
          blog={blog}
          className="w-fit text-white text-center lg:text-left font-bold rounded-full py-3 px-10 mb-4 bg-navy mx-auto lg:mx-0"
        />
        <div className="flex flex-col gap-2 w-fit">
          <span className="w-[25%] lg:w-[50%] h-[3px] rounded-full bg-navy mx-auto lg:mx-0" />
          {blog.heading?.length ? (
            <h1 className="text-center lg:text-left pr-2 lg:mb-4 text-navy">
              {blog.heading}
            </h1>
          ) : null}
          {blog.subheading?.length ? (
            <h4 className="text-center lg:text-left pr-2 lg:mb-4 w-[75%] lg:w-[60%] mx-auto lg:mx-0">
              {blog.subheading}
            </h4>
          ) : null}
        </div>
        <div className="flex flex-col items-center justify-center items-center lg:items-start py-2">
          <p>
            <span className="text-navy font-semibold">Author:</span> Bryce Mathew
            Watts
          </p>
          <p>
            <span className="text-navy font-semibold">Date:</span> {blog.date}
          </p>
        </div>
      </div>
      <div
        className="bg-cover bg-no-repeat h-[200px] md:min-h-[344px] md:h-full rounded-[30px]"
        style={{
          backgroundImage: `url("${require(`../../assets/img/news/${blog.heading
            .toLowerCase()
            .split(" ")
            .join("-")}.png`)}")`,
        }}
      />
    </div>
  ) : null;
};

export default BlogHead;
