import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { focusDomains } from "../data/data";

import Brands from "../components/brands/Brands";

const BrandsScreen = () => {
  const { domain } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (![...focusDomains.map((fd) => fd.slug), "main"].includes(domain))
      navigate("/brands/domain/main");
  }, [domain, navigate]);

  return (
    <div className="container">
      <Brands
        collection={focusDomains.find((fd) => fd.slug === domain)?.brands || []}
      />
    </div>
  );
};

export default BrandsScreen;
