import React from "react";

const SectorCard = ({ sector }) => {
  return (
    <div className="relative sector-card text-white overflow-hidden w-full lg:w-[22%] grow">
      <div className="text flex flex-col gap-2 absolute with-transition w-[80%]">
        <span className="w-[50px] h-[3px] bg-white rounded-full" />
        <h3>{sector.heading}</h3>
        <div className="relative">
          <p className="absolute font-semibold with-transition">
            {sector.description}
          </p>
        </div>
      </div>
      <img
        src={require(`../../assets/img/community/sectors/${sector.heading}.png`)}
        alt=""
        className="w-full"
      />
    </div>
  );
};

export default SectorCard;
