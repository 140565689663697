import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { brands } from "../../data/data";

import PageHeadline from "../global/PageHeadline";

const Brands = ({
  collection,
  inline,
  onlyVisuals,
  visualClassNames,
  filterHeros,
  cta,
}) => {
  const [filteredBrands, setFilteredBrands] = useState([]);

  useEffect(() => {
    setFilteredBrands(
      collection?.length ? collection : brands.map((brand) => brand.slug),
    );
  }, [collection, filterHeros]);

  return (
    <div className={!inline ? "py-[140px]" : ""}>
      {!onlyVisuals ? (
        <PageHeadline
          headline="Brands"
          description={[
            "Forager International specializes in innovative solutions for the TACH sector (tourism, arts, culture, and heritage). Through business partnerships and investments, the services available continue to grow in subject and location. We are proud of what we are creating and continue to develop new opportunities to deepen our relationship with the TACH sector around the world towards a mission of community and economic development through these key sectors.",
          ]}
        />
      ) : null}
      <div className="flex justify-center justify-items-start items-center w-full flex-wrap py-4">
        {filteredBrands.filter(brand => brand !== "fnd")?.map((brand, brandIndex) => (
          <Link
            key={brandIndex}
            to={`/brands/${brand}`}
            className={`w-full md:w-[30%] h-auto rounded-xl hover:bg-white p-8 flex items-center justify-center with-transition ${
              visualClassNames || ""
            }`}
          >
            <img
              src={require(`../../assets/logos/brands/${brand}.svg`)}
              alt={`brand-${brand}`}
            />
          </Link>
        ))}
      </div>
      {cta ? (
        <Link
          to={cta.to}
          className={`w-fit flex justify-center items-center text-center mx-auto px-8 py-2 border-2 rounded-full hover:bg-white text-white border-navy bg-navy hover:text-navy font-semibold with-transition`}
        >
          {cta.text}
        </Link>
      ) : null}
    </div>
  );
};

export default Brands;
