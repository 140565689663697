import React from "react";

import Bryce from "../../assets/img/story/bryce.png";
import HomeTown from "../../assets/img/story/gibsons.png";

const FoundingStory = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-16 lg:gap-8 items-center justify-center">
      <div className="flex flex-col gap-8 grow">
        <h1>Founding Story</h1>
        <div className="flex flex-col gap-4">
          <p>
            In 2016, Bryce Mathew Watts, drawing from his anthropology
            background, moved to London to use his expertise in creating content
            for the tourism sector. This led to the establishment of Forager
            Media Group in 2017, focusing on content development for various
            brands.
          </p>
          <p>
            When the pandemic hit, Bryce recognized the importance of industries
            in the TACH sector (Tourism, Arts, Culture & Heritage) as pillars of
            the community. This realization prompted him to redefine Forager's
            mission. Through collaboration with the BC Museums Association,
            Bryce launched The VR Voyage, providing crucial support and
            revenue-generating programs for struggling organizations.
          </p>
          <p>
            Following the success of aiding historical sites and brands, Forager
            International introduced its own integrated partnership program to
            further expand its impact by nurturing partnerships and developing
            tailored solutions. As of now, Forager International has helped
            raise more than a million dollars for partners to build capacity,
            increase awareness, and further their mandates, and seeks to foster
            meaningful relationships and drive positive change.
          </p>
        </div>
      </div>
      <div className="flex flex-row lg:flex-col gap-4 justify-center items-center">
        <img
          src={Bryce}
          alt="founder"
          className="grow lg:grow-0 w-[45%] lg:w-[460px] lg:aspect-square"
        />
        <img
          src={HomeTown}
          alt="hometown"
          className="grow lg:grow-0 w-[45%] lg:w-[460px] lg:aspect-square"
        />
      </div>
    </div>
  );
};

export default FoundingStory;
