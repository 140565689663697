import React from "react";
import { Link } from "react-router-dom";

import { slugify } from "../../constants/helpers";

const NewsCard = ({ news }) => {
  return news ? (
    <div className="rounded-2xl flex flex-col overflow-hidden w-full lg:w-[25%]">
      <div
        className="h-[300px] w-full aspect-square bg-norepeat bg-cover bg-center"
        style={{
          backgroundImage: `url(${require(`../../assets/img/news/${
            news.hasVisual ? slugify(news.heading) : "default"
          }.png`)})`,
        }}
      ></div>
      <div className="p-6 flex flex-col gap-6 items-start justify-center bg-white">
        <h4 className="text-navy">{news.heading}</h4>
        <p>{news.bio}...</p>
        {news.hasLink ? (
          <Link
            to={`/newsroom/${slugify(news.heading)}`}
            className="w-fit text-denim hover:text-navy inline-flex font-bold flex gap-1 hover:gap-2 with-transition"
          >
            <span>Explore</span>
            <i
              className="iconify my-auto"
              data-icon="ep:arrow-up-bold"
              data-rotate="90deg"
            />
          </Link>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default NewsCard;
