import PhoneIcon from "../assets/img/contact/phone-icon.svg";
import EmailIcon from "../assets/img/contact/email-icon.svg";
import SalesIcon from "../assets/img/contact/sales-icon.svg";
import WorkIcon from "../assets/img/contact/work-icon.svg";

export const links = [
  {
    title: "Home",
    route: "/",
    inHeader: false,
    inDrawer: true,
    inFooter: true,
  },
  {
    title: "About",
    route: "/about",
    inHeader: true,
    inDrawer: true,
    inFooter: true,
    children: ["Our Community", "Our Focus", "Our Team", "Our Story"],
  },
  {
    title: "Brands",
    route: "/brands/domain/main",
    inHeader: true,
    inDrawer: true,
    inFooter: true,
  },
  {
    title: "Foundation",
    route: "/brands/fnd",
    inHeader: true,
    inDrawer: true,
    inFooter: true,
  },
  {
    title: "Newsroom",
    route: "/newsroom",
    inHeader: true,
    inDrawer: true,
    inFooter: true,
  },
  {
    title: "Careers",
    route: "/careers",
    inHeader: true,
    inDrawer: true,
    inFooter: true,
  },
  {
    title: "Contact",
    route: "/contact",
    inHeader: true,
    inDrawer: true,
    inFooter: true,
  },
];

export const contact = [
  {
    icon: PhoneIcon,
    title: "Phone / WhatsApp",
    paragraph: "+ 1 250 569 4401 ext. 108",
  },
  {
    icon: EmailIcon,
    title: "General Enquiries",
    paragraph: "info@foragerinternational.com",
  },
  {
    icon: SalesIcon,
    title: "Investor Enquiries",
    paragraph: "invest@foragerinternational.com",
  },
  {
    icon: WorkIcon,
    title: "Partnership Enquiries",
    paragraph: "partners@foragerinternational.com",
  },
];

export const emails = [
  {
    title: "General Enquiries",
    paragraph: "info@foragerinternational.com",
  },
  {
    title: "Investor Enquiries",
    paragraph: "invest@foragerinternational.com",
  },
  {
    title: "Partnership Enquiries",
    paragraph: "partners@foragerinternational.com",
  },
];

export const corporateData = {
  location: {
    city: "Gibsons",
    province: "BC",
    country: "Canada",
  },
  phone: "+1 250 569 4401",
  emails: [
    {
      title: "General Enquiries",
      paragraph: "info@foragerinternational.com",
    },
    {
      title: "Investor Enquiries",
      paragraph: "invest@foragerinternational.com",
    },
    {
      title: "Partnership Enquiries",
      paragraph: "partners@foragerinternational.com",
    },
  ],
};

export const networks = [
  {
    name: "facebook",
    href: "",
    icon: "ri:facebook-fill",
  },
  {
    name: "instagram",
    href: "",
    icon: "mdi:instagram",
  },
  {
    name: "tiktok",
    href: "",
    icon: "ic:baseline-tiktok",
  },
  {
    name: "linkedin",
    href: "https://www.linkedin.com/company/forager-international",
    icon: "mingcute:linkedin-fill",
  },
];

export const focusDomains = [
  {
    heading: "Accounting",
    description:
      "The need for professional financial services is paramount in ensuring the sustainability of organizations within the TACH sector. Forager International's investment into the financial services industry stemmed from repeated expressions of need from our partners. We offer tailored financial solutions to support our partners' growth and stability.",
    slug: "accounting",
    brands: ["ffs"],
  },
  {
    heading: "Creative",
    description:
      "Creativity fuels expression and engagement within the TACH sector. Forager International's creative brands specialize in branding, marketing, web design, audio and video productions, and publishing, developing projects around the world. By leveraging creativity, Forager International enhances the visibility and impact of partners within the community.",
    slug: "creative",
    brands: ["fmg", "fdi", "fvi", "fpd", "fpb"],
  },
  {
    heading: "Education",
    description:
      "In the dynamic landscape of education, Forager International recognizes the importance of adapting to new learning environments. Forager International brands specialize in educational program development services, with a keen focus on online and digital learning experiences. While the primary focus is on school-aged children, Forager Education understands that education is a lifelong endeavour catering to learners of all ages.",
    slug: "education",
    brands: ["fed", "vrc"],
  },
  {
    heading: "Mixed Reality",
    description:
      "Mixed reality opens new realms of possibilities for immersive experiences. Forager International has been at the forefront of mixed reality projects, developing platforms for virtual reality experiences that blend functionality and excitement. From educational applications to interactive public experiences, our mixed reality brands redefine engagement in the TACH sector.",
    slug: "mixed-reality",
    brands: ["fvi", "vrv", "vrc"],
  },
  {
    heading: "Technology",
    description:
      "Technology is the cornerstone of innovation across all sectors. Forager International prioritizes technology adoption to enhance the operations of organizations within the Forager Partnership Program. Whether it's optimizing tourism experiences, developing educational programs, or streamlining accounting activities, Forager International leads technology adoption programs and develops custom software solutions to drive innovation.",
    slug: "technology",
    brands: ["fvi", "vrv", "vrc"],
  },
  {
    heading: "Travel & Tourism",
    description:
      "Forager International understands the transformative power of travel and tourism. Our brands in the tourism industry focus on offering innovative services that blend online, in-person, and digital experiences. Notably, one of our tourism brands was recognized as a finalist in an international startup competition sponsored by the Government of Portugal.",
    slug: "travel-&-tourism",
    brands: ["vrv"],
  },
];

export const communitySectors = [
  {
    heading: "Tourism",
    description:
      "We foster local economies and cross-cultural understanding by connecting people with new experiences, cultures, and communities.",
  },
  {
    heading: "Arts",
    description:
      "We inspire, provoke and unite by enriching the cultural fabric of our communities, thus making them more vibrant and inclusive.",
  },
  {
    heading: "Culture",
    description:
      "We preserve and promote intangible cultural heritage to honour our past and create a sense of belonging for future generations.",
  },
  {
    heading: "Heritage",
    description:
      "We safeguard our natural and tangible cultural heritage to celebrate our diversity and ensure that future generations learn from the past.",
  },
];

export const brands = [
  {
    slug: "fmg",
    hero: true,
    description: [
      "Forager Media Group Canada was established in 2021 and has swiftly evolved into a leading provider of digital services across four distinct areas. With a primary focus on providing services to partners in the TACH sector, Forager Media Group has cultivated deep relationships with its partners, contributing to the development of over 70 projects spanning all four business divisions. Forager Media Group acts as the catalyst for innovative change in the creative industry by fostering relationships with entrepreneurs in regions across Canada and launching local solutions that tie into the global network of professionals Forager Media Group works with.",
    ],
    cta: {
      description:
        "As the parent company for other brands in the creative industry, Forager Media Group is committed to pushing the boundaries of collaboration in the TACH sector and bringing all the benefits of the creative industry to the Forager Partnership Program. The majority of our projects have garnered federal, provincial, or regional support and recognition, affirming our dedication to excellence and innovation.",
      btn: {
        text: "Visit Forager Media Group",
        link: "https://foragermediagroup.com",
        external: true,
      },
    },
    children: [
      {
        slug: "fdi",
        description:
          "Our team of innovative graphic designers, brand developers, UX designers, and digital marketers is dedicated to elevating partner brands to new heights. Whether it's crafting a stunning website, creating custom branding, or implementing promotional strategies, this division delivers results.",
      },
      {
        slug: "fvi",
        description:
          "With seasoned virtual and augmented reality developers at the helm, our virtual division is equipped to harness the power of immersive technology. From captivating VR tours to engaging AR scavenger hunts and virtual learning resources, this division brings partners' visions to life.",
      },
      {
        slug: "fpd",
        description:
          "Our production division boasts expert producers, editors, and audio engineers who transform partner storyboards into compelling experiences. Whether it's producing podcasts, YouTube series, or promotional videos, this division excels in delivering exceptional content.",
      },
      {
        slug: "fpb",
        description:
          "Fueled by creative writers, meticulous editors, and publishing specialists, our publishing division turns partner ideas into reality. From curating online magazines to preparing books for publishing, this division specializes in bringing partners' narratives to the forefront.",
      },
    ],
  },
  {
    slug: "fdi",
    hero: false,
    description: [
      "Forager Digital made its debut in Canada in 2021, igniting a wave of innovation and creativity in the digital landscape. Specializing in graphic design, branding, UX design, and digital marketing, Forager Digital has not only transformed the online presence of numerous partners but has also redefined the standards of excellence in the TACH sector.",
      "Forager Digital made its debut in Canada in 2021, igniting a wave of innovation and creativity in the digital landscape. Specializing in graphic design, branding, UX design, and digital marketing, Forager Digital has not only transformed the online presence of numerous partners but has also redefined the standards of excellence in the TACH sector.",
    ],
    cta: {
      description: "",
      btn: {
        text: "Visit Forager Digital",
        link: "https://foragerdigital.com",
        external: true,
      },
    },
    children: [],
  },
  {
    slug: "fvi",
    hero: false,
    description: [
      "Forager Virtual debuted in Canada in 2021, ushering in a new era of immersive experiences and technological innovation. Specializing in virtual tours, virtual educational experiences, augmented reality (AR) experiences, and digital self-guided tour development, Forager Virtual has redefined the boundaries of storytelling and exploration in the TACH sector (tourism, arts, culture, and heritage).",
      "Forager Virtual's impact extends far beyond the confines of traditional storytelling and exploration. Through our collaboration with partners in the TACH sector, we have not only transformed how audiences engage with cultural heritage and educational content but have also opened up new avenues for connection and discovery. By harnessing the power of mixed reality, Forager Virtual is shaping the future of exploration and learning, one virtual experience at a time.",
    ],
    cta: {
      description: "",
      btn: {
        text: "Visit Forager Virtual",
        link: "https://foragermediagroup.com/division/virtual/about",
        external: true,
      },
    },
    children: [],
  },
  {
    slug: "fpd",
    hero: false,
    description: [
      "Forager Productions emerged onto the Canadian scene in 2021, heralding a new era of storytelling and creativity in the TACH sector (tourism, arts, culture, and heritage). Specializing in audio and video productions, Forager Productions is dedicated to bringing partners' narratives to life in captivating and impactful ways.",
      "Forager Productions' impact extends far beyond the realm of audio and video production. Through our collaboration with partners in the TACH sector, we have not only elevated their stories but have also strengthened connections within communities and fostered a deeper appreciation for cultural heritage and the arts. By harnessing the power of storytelling and creativity, Forager Productions is shaping the future of the TACH sector, one narrative at a time.",
    ],
    cta: {
      description: "",
      btn: {
        text: "Visit Forager Productions",
        link: "https://foragermediagroup.com/division/productions/about",
        external: true,
      },
    },
    children: [],
  },
  {
    slug: "fpb",
    hero: false,
    description: [
      "Forager Publishing emerged onto the Canadian publishing scene in 2021 as a standalone brand but has been active since 2015. Launching the brand heralded a new era of literary excellence and digital content development in the TACH sector (tourism, arts, culture, and heritage). Specializing in magazine and book publishing, as well as digital content development, Forager Publishing is dedicated to nurturing ideas and inspiring minds through its diverse range of publications and digital initiatives.",
      "Forager Publishing's impact extends far beyond the pages of our publications and digital content. Through our collaboration with partners in the TACH sector, we have not only showcased their stories and achievements but have also fostered a deeper sense of connection and understanding within communities. By nurturing ideas and inspiring minds, Forager Publishing is shaping the future of the TACH sector, one publication at a time.",
    ],
    cta: {
      description: "",
      btn: {
        text: "Visit Forager Publishing",
        link: "https://foragermediagroup.com/division/publishing/about",
        external: true,
      },
    },
    children: [],
  },
  {
    slug: "fed",
    hero: false,
    description: [
      "Forager Education emerged as an award-winning beacon of educational innovation in Canada in 2021. Driven by a passion for fostering lifelong learning and empowering individuals to reach their full potential, Forager Education specializes in educational program development, learning kit creation, and educational platform management and is committed to revolutionizing the educational landscape within the TACH sector (tourism, arts, culture, and heritage).",
      "Forager Education won the Business/Arts Community Impact Award in 2023 because its impact extends far beyond the classroom. Through our collaboration with partners in the TACH sector, we have not only transformed educational experiences but have also empowered individuals to explore, discover, and connect with the world around them. By harnessing the power of educational experiences, Forager Education is shaping the future of education, one mind at a time.",
    ],
    cta: {
      description: "",
      btn: {
        text: "Visit Forager Education",
        link: "https://foragereducation.com",
        external: true,
      },
    },
    children: [],
  },
  {
    slug: "ffs",
    hero: true,
    description: [
      "Forager Financial Services launched in Canada in 2022, offering professional outsourced accounting services tailored to meet the unique needs of partners in the TACH sector (tourism, arts, culture, and heritage). With a steadfast commitment to financial integrity and efficiency, Forager Financial Services empowers organizations to thrive, excel, and make a lasting impact within their communities.",
      "Serving as a pillar of financial stability for partners in the TACH sector, Forager Financial Services brings together expertise in bookkeeping, financial reporting, payroll, and tax preparation so partners have the financial insights and resources they need to make informed decisions, allocate resources effectively, and achieve their strategic objectives. With a team of seasoned professionals at the helm, Forager Financial Services delivers unparalleled expertise and guidance, enabling partners across Canada to navigate complex financial landscapes with confidence and ease. Through its unwavering dedication to financial excellence, Forager Financial Services is not just a service provider; it's a trusted ally and strategic partner, empowering organizations to achieve their financial goals, fulfill their missions, and make a meaningful difference in the world.",
    ],
    cta: {
      description: "",
      btn: {
        text: "Visit Forager Financial Services",
        link: "https://foragerfinancial.com",
        external: true,
      },
    },
    children: [],
  },
  {
    slug: "vrc",
    hero: true,
    description: [
      "Formerly known as The VR Voyage Classroom, the Classroom Voyager stands as a pioneering platform in educational innovation, recognized for its excellence and impact within the British Columbia school system most recently by Business/Arts, which awarded it the Community Impact Award for 2023. Developed as a partnership between the BC Museums Association and Forager Education (Canada), Classroom Voyager (British Columbia) serves as a dynamic marketplace for partners to publish and promote their educational programs and resources, aggregating content from arts, culture, and heritage institutions across BC.",
      "At the heart of Classroom Voyager lies a commitment to empowering partners in the TACH sector. By providing a centralized platform for institutions to showcase and share their educational programs and resources, Classroom Voyager amplifies their reach and impact, connecting them with schools and educators across British Columbia. Through this collaboration, partners gain invaluable opportunities to engage with students, raise awareness about cultural heritage, and inspire the next generation of learners, thereby enriching the cultural fabric of the community and preserving its heritage for generations to come.",
    ],
    cta: {
      description: "",
      btn: {
        text: "Visit Classroom Voyager",
        link: "https://www.vrvclassroom.com",
        external: true,
      },
    },
    children: [],
  },
  {
    slug: "vrv",
    hero: true,
    description: [
      "The VR Voyage stands as a revolutionary tourism platform, seamlessly blending in-person, virtual, and digital experiences to offer visitors a unique opportunity to engage with new destinations through the lens of tourism, arts, culture, and heritage organizations. With a commitment to innovation and immersive storytelling, The VR Voyage transports travellers to captivating destinations, inviting them to embark on a journey of discovery like never before.",
      "The VR Voyage's innovative approach to tourism has garnered recognition and support from esteemed institutions worldwide. Funded by the Government of Canada, The VR Voyage has been at the forefront of driving innovation in the tourism industry, leveraging cutting-edge technology to enhance visitor experiences and promote cultural exchange. Additionally, The VR Voyage was honoured as a finalist in the Acceler@Tech in Portugal tourism startup competition, sponsored by the Government of Portugal, further validating its impact and potential on the global stage.",
    ],
    cta: {
      description: "",
      btn: {
        text: "Visit The VR Voyage",
        link: "https://thevrvoyage.com",
        external: true,
      },
    },
    children: [],
  },
  {
    slug: "fnd",
    hero: false,
    description: [
      "Established in 2013 alongside the inaugural Forager-branded company, Forager Foundation embodies a steadfast commitment to preserving and promoting the world's natural and cultural heritage. With a mission deeply rooted in conservation and education, the Foundation spearheads initiatives aimed at safeguarding our communities’ most precious treasures for future generations.",
    ],
    domain: {
      heading: "Management Of Heritage Sites",
      description:
        "In 2023, Forager Foundation embarked on an updated focus, launching a groundbreaking program dedicated to the management of at-risk natural and cultural heritage sites. This initiative ensures the long-term vitality and sustainability of these invaluable landmarks. Notably, Forager Foundation entered into a management agreement with the Province of British Columbia to oversee the Yale Historic Site until at least 2028. Additionally, the Foundation assumed temporary management of the Point Ellice House Museum & Gardens at the request of the Province of British Columbia, demonstrating its commitment to preserving heritage sites of cultural significance. Presently, Forager Foundation collaborates with local and provincial governments to identify and integrate more sites into this growing program, inspired by the esteemed models of Parks Canada and English Heritage.",
    },
    cta: {
      heading: "Call to Action",
      description: `Join us in our mission to safeguard and celebrate our natural and cultural heritage. Whether you're passionate about conservation, education, or community engagement, there are numerous ways to get involved, make a difference, and leave a lasting impact. Visit the Forager Foundation website to explore opportunities for volunteering, making donations, and becoming a member.
    
    Together, let's preserve the past and inspire the future.`,
      btn: {
        text: "Visit Forager Foundation",
        link: "https://foragerfoundation.com",
        external: true,
      },
    },
    children: [],
    givesBack: [
      "Forager Foundation's impact extends far beyond its preservation efforts, thanks to the unwavering support of Forager International and all companies within the Forager International network. With a pledge to donate at least 1% of all revenue, these entities actively contribute to the Foundation's noble cause through financial and discounted professional services, ensuring the continued protection and celebration of our natural and cultural heritage.",
    ],
  },
];

export const newsList = [
  {
    heading: "The VR Voyage Is A Finalist",
    subheading: "Selected For The Aceler@Tech In Portugal Startup Competition",
    date: "12/04/2021",
    bio: "We are thrilled to share that our innovative tourism platform, The VR Voyage, has been selected as a finalist in the esteemed Aceler@Tech In Portugal Startup Competition",
    description: [
      "We are thrilled to share that our innovative tourism platform, The VR Voyage, has been selected as a finalist in the esteemed Aceler@Tech In Portugal Startup Competition. This recognition brings international attention to our platform and highlights our commitment to offering unique experiences for travellers across the globe.",
      "Aceler@Tech In Portugal Startup Competition seeks to identify tourism startups with high growth potential and offers cash prizes and support for establishing a presence in Portugal. This competition aligns with our vision of expanding The VR Voyage's reach and showcasing experiences from around the world.",
      "The VR Voyage is a revolutionary tourism platform that seamlessly combines in-person, virtual, and digital experiences, providing visitors with an immersive opportunity to discover new destinations through the lens of tourism, arts, culture, and heritage organizations. Our platform transports travellers to fascinating places, inviting them to embark on a journey of discovery like never before.",
      "Selected for our innovative approach and growth potential, The VR Voyage distinguished itself among competitors from around the globe. This recognition demonstrates the platform's capacity to contribute to the global tourism industry.",
      "Participating in this competition has allowed us to showcase our platform's potential and connect with an international network of professionals. We are honored to be a finalist and excited about the possibilities that lie ahead.",
    ],
    about: [
      {
        entity: "About The VR Voyage",
        description:
          "The VR Voyage is a Canadian-based tourism platform offering immersive and innovative experiences for travellers seeking to explore new destinations through the lenses of tourism, arts, culture, and heritage organizations.",
      },
      {
        entity: "About Aceler@Tech In Portugal Startup Competition",
        description:
          "Aceler@Tech In Portugal Startup Competition is an initiative organized by Acredita Portugal to identify international tourism startups with high growth potential and provide them with financial, networking, and business support to expand their ventures.",
      },
    ],
    hasLink: true,
    hasVisual: true,
  },
];

export const values = [
  {
    title: "Mutual Respect",
    description:
      "We value diverse perspectives and prioritize open communication within our team.",
  },
  {
    title: "Adaptability",
    description:
      "We embrace change and tailor our solutions to meet the distinct needs of each partner.",
  },
  {
    title: "Collaboration",
    description:
      "We believe in the power of partnership and work hand-in-hand with communities to drive positive change.",
  },
  {
    title: "Balance",
    description:
      "We strive to achieve excellence while maintaining a healthy work-life balance for our team members.",
  },
  {
    title: "Giving Back",
    description:
      "We are dedicated to giving back to the communities we serve, enriching lives through meaningful initiatives.",
  },
];

export const managers = [
  {
    title: "Bryce Mathew Watts",
    description: "Founder & CEO",
  },
  {
    title: "Anderson Guerrero Gomez",
    description: "Founder & Senior Accountant, Forager Financial Services",
  },
];

export const team = [
  {
    title: "Bryce Mathew Watts",
    position: "Founder & CEO",
    brand: "Forager International",
    story:
      "Bryce is a published anthropologist and award-winning entrepreneur with a degree from the University of British Columbia. He possesses a unique blend of academic knowledge and practical experience, particularly within the tourism, arts, culture, and heritage sector (TACH). Alongside his role at Forager International, Bryce is the Director of Cultural Heritage at Forager Foundation, Canadian Innovation Taskforce Lead for the G20 Young Entrepreneurs’ Alliance (YEA), and four-time Canadian delegate for the G20 YEA Summit. For over a decade, Bryce has dedicated himself to initiatives that seamlessly blend the boundaries between for-profit and non-profit, always with a focus on strengthening communities.",
  },
  {
    title: "Anderson Guerrero",
    position: "Founder & Senior Accountant",
    brand: "Forager Financial Services",
    story:
      "Anderson is a graduate of the prestigious Pontifical Javierian University in Bogotá, Colombia, with a degree in accounting. His professional career started at Ernst & Young’s Colombian headquarters. He later moved to Sanofi, a multinational pharmaceutical company, running the intercompany accounting team for all of Latin America. After that, Anderson embraced entrepreneurism by launching Forager Financial Services. Since moving to Canada in 2023, Anderson has branched out from the world of finance to include advocacy for natural and cultural heritage initiatives in Canada and beyond. Using his background, Anderson strengthens the TACH sector through corporate best practices and financial literacy programs.",
  },
  {
    title: "Mahmoud Ali Hassan",
    position: "Web Lead",
    brand: "Forager Media Group",
    story: "",
  },
  {
    title: "Miller Garcia Cardozo",
    position: "Design Lead",
    brand: "Forager Digital",
    story: "",
  },
];
