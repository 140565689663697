import React from "react";
import { Link } from "react-router-dom";

const DomainCard = ({ domain, noLink, className }) => {
  return domain ? (
    <div
      className={`lg:w-[75%] mx-auto flex flex-col lg:flex-row gap-8 lg:gap-16 justify-center items-center ${
        className || ""
      }`}
    >
      <img
        className={`w-full lg:w-[30%]`}
        src={require(`../../assets/img/focus/domains/${domain.heading
          .toLowerCase()
          .split(" ")
          .join("-")}.png`)}
        alt={domain.heading}
      />
      <div className="grow flex flex-col gap-8">
        <h1>{domain.heading}</h1>
        <p>{domain.description}</p>
        {!noLink ? (
          <Link
            to={`/brands/domain/${domain.heading
              .toLowerCase()
              .split(" ")
              .join("-")}`}
            className="w-fit text-navy hover:text-denim inline-flex font-bold flex gap-1 hover:gap-2 with-transition"
          >
            <span>View Brands</span>
            <i
              className="iconify my-auto"
              data-icon="ep:arrow-up-bold"
              data-rotate="90deg"
            />
          </Link>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default DomainCard;
