import React from "react";

const FooterContactColumn = ({ corporateData }) => {
  return (
    <div className="flex flex-col gap-5 md:mx-auto capitalize text-white">
      <h2 className="text-white text-2xl font-bold">Contact</h2>
      <p className="font-bold">
        Company HQ:
        <span className="block font-normal">
          {`${corporateData.location.city} ${corporateData.location.province}, ${corporateData.location.country}`}
        </span>
      </p>
      <p className="font-bold">
        Phone:
        <a
          href={`https://api.whatsapp.com/send?phone=${corporateData.phone?.replace(
            /\D/g,
            "",
          )}`}
          target="_blank"
          rel="noreferrer"
          className="block font-normal hover:text-cyan with-transition w-fit"
        >
          {corporateData.phone}
        </a>
      </p>
      {corporateData.emails.map((email, emailIndex) => (
        <p className="font-bold" key={emailIndex}>
          {email.title}:
          <a
            href={`mailto:${email.paragraph}`}
            target="_blank"
            rel="noreferrer"
            className="block font-normal hover:text-cyan with-transition lowercase w-fit"
          >
            {email.paragraph}
          </a>
        </p>
      ))}
    </div>
  );
};

export default FooterContactColumn;
