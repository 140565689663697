import React from "react";
import { Link } from "react-router-dom";

import ContactForm from "../contact/ContactForm";

const BecomePartner = ({ isContact, className }) => {
  return (
    <>
      <div
        className={`xl:max-w-[80%] xl:mx-auto grid md:grid-cols-2 gap-8 xl:gap-[75px] md:items-center justify-start text-left ${
          className || ""
        }`}
      >
        <div
          className="bg-cover bg-no-repeat h-[417px] md:h-[648px] rounded-[30px]"
          style={{
            backgroundImage: `url(${require("../../assets/img/home/partner.jpg")})`,
          }}
        />
        <div className="flex flex-col gap-8">
          <h2 className="text-navy lg:text-[52px] xl:text-[56px] whitespace-nowrap flex flex-col">
            {"Forager Partnership Program".split(" ").map((word, wordIndex) => (
              <span key={wordIndex}>{word}</span>
            ))}
          </h2>
          <p>
            This program fosters long-lasting relationships with our partners,
            providing them with exclusive resources and support services
            tailored to their needs.
          </p>
          <p>
            For just $150/year for for-profit organizations and free for
            non-profits, partners can access a wealth of resources developed by
            our dedicated teams. From skills-sharing to streamlined operations,
            we're here to help organizations thrive.
          </p>
          <p>
            But our partnership goes beyond resources; it's about collaboration
            and mutual growth. Our strategic partners actively promote our
            products and services, earning a share of the revenue generated—a
            testament to our commitment to supporting the organizations that
            support us.
          </p>
          {isContact ? (
            <Link
              to="/contact"
              className={`bg-navy lg:hover:bg-denim lg:hover:text-navy-blue-ffs mt-8 text-white py-3 lg:px-9 lg:w-fit mx-auto lg:mx-0 block text-center rounded-full font-bold with-transition`}
            >
              Join Us
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ContactForm defaultReason="Partnership Enquiry" />
    </>
  );
};

export default BecomePartner;
