import React, { useState } from "react";

import { team } from "../data/data";

import PageHeadline from "../components/global/PageHeadline";
import Leadership from "../components/team/Leadership";
import MemberInfoModal from "../components/team/MemberInfoModal";
import CTA from "../components/global/CTA";

const OurTeamScreen = () => {
  const [selectedMember, setSelectedMember] = useState(null);

  const memberHandler = (member) => {
    setSelectedMember(member);
  };

  return (
    <div className="container pt-[140px]">
      {selectedMember ? (
        <>
          <MemberInfoModal
            member={selectedMember}
            onClose={() => setSelectedMember(null)}
          />
        </>
      ) : null}
      <PageHeadline
        headline="Our Team"
        description={[
          "Forager International takes inspiration from the cultural anthropologist Wade Davis. “The world in which you were born is just one model of reality. Other cultures are not failed attempts at being you; they are unique manifestations of the human spirit.” That is why we actively try to build a team at all levels and within each Forager brand that represents as many different cultures and identities as possible. Through diversity, we are able to tackle even the most challenging of tasks because we have a deep diversity of perspectives.",
        ]}
      />
      <Leadership
        headline="Leadership Team"
        team={team}
        className="py-[100px] lg:py-[140px]"
        onClick={memberHandler}
      />
      <CTA
        cta={{
          heading: "Opportunities Across Forager Brands",
          description:
            "If you are interested in working with the team showcased here, check out our careers page for current opportunities.",
          btn: { link: "/careers", text: "Browse Openings " },
          className: "lg:w-[40%] mx-auto text-center pt-[50px]",
        }}
      />
    </div>
  );
};

export default OurTeamScreen;
