import React from "react";

import { corporateData, links } from "../../data/data";

import whiteLogo from "../../assets/logos/logo-white.svg";

import SocialLinks from "./SocialLinks";
import Copyrights from "./Copyrights";
import FooterContactColumn from "./FooterContactColumn";
import FooterNavigationMenu from "./FooterNavigationMenu";

const AppFooter = ({ userInfo }) => {
  return (
    <footer className="bg-denim">
      <div className="container">
        <div className="bg-denim text-white relative">
          <div className="grid md:grid-cols-3 gap-12 pt-20 pb-10 xl:pt-16">
            <div>
              <img src={whiteLogo} alt="Logo" className="w-44" />
              <p className="mt-7 max-w-[447px]">
                Forager International is an investment management business
                developing innovative service solutions for partners around the
                world that engage with heritage. We build off the guiding forces
                of collaboration, community development, inclusivity, and
                sustainability.
              </p>
              <p className="mt-7 font-bold">Follow Us</p>
              <SocialLinks
                className="mt-5"
                iconClassName={"text-white hover:text-cyan"}
              />
            </div>
            <FooterNavigationMenu userInfo={userInfo} links={links} />
            <FooterContactColumn corporateData={corporateData} />
          </div>
          <Copyrights />
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
