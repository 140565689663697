import React from "react";
import { Link } from "react-router-dom";

const CTA = ({ cta }) => {
  return (
    <div
      className={`flex flex-col gap-8 items-center justify-center ${
        cta?.className || ""
      }`}
    >
      {cta?.heading ? <h1>{cta?.heading}</h1> : null}
      {cta?.description ? (
        <span className="lg:text-center lg:w-[60%] mx-auto flex flex-col gap-4">
          {cta?.description?.split("\n").map((paragraph, paragraphIndex) => (
            <p key={paragraphIndex}>{paragraph}</p>
          ))}
        </span>
      ) : null}
      {cta?.btn ? (
        <>
          {cta?.btn?.external ? (
            <a
              href={cta?.btn.link}
              target="_blank"
              rel="noreferrer"
              className="bg-navy lg:hover:bg-denim text-white py-3 px-10 lg:px-20 w-full lg:w-fit mx-auto block text-center rounded-full font-bold mb-24 lg:mb-36 with-transition"
            >
              {cta?.btn.text}
            </a>
          ) : (
            <Link
              className="bg-navy lg:hover:bg-denim text-white py-3 px-10 lg:px-20 w-full lg:w-fit mx-auto block text-center rounded-full font-bold mb-24 lg:mb-36 with-transition"
              to={cta?.btn.link}
            >
              {cta?.btn.text}
            </Link>
          )}
        </>
      ) : null}
    </div>
  );
};

export default CTA;
