import React from "react";

const MemberInfoModal = ({ member, onClose }) => {
  return member ? (
    <div className="fixed w-full h-full left-0 top-0 z-[100] flex justify-center items-center overflow-hidden">
      <div className="top-0 left-0 absolute w-full h-full bg-denim opacity-50 z-[-1]"></div>
      <div className="w-full lg:w-[60%] bg-white px-[25px] lg:px-[100px] pt-[20px] pb-[50px] lg:py-[100px] flex flex-col lg:flex-row gap-8 lg:gap-16 justif-center items-center rounded-[40px] relative container mx-[20px] lg:mx-0">
        <div
          className={`absolute p-2 lg:p-4 aspect-square bg-grey text-denim fixed right-[40px] lg:right-5 top-[35px] lg:top-5 grid place-content-center rounded-full cursor-pointer hover:bg-navy hover:text-white with-transition`}
          onClick={onClose}
        >
          <i
            className="iconify text-xl lg:text-2xl"
            data-icon="mingcute:close-line"
          />
        </div>
        <img
          src={require(`../../assets/img/team/leadership/${member.title
            ?.toLowerCase()
            .split(" ")
            .join("-")}.png`)}
          alt=""
          className="w-full lg:w-[50%]"
        />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h4 className="font-bold text-navy">{member.title}</h4>
            <h4 className="font-normal text-xl">{member.position}</h4>
          </div>
          <p className="text-sm lg:text-base">{member.story}</p>
        </div>
      </div>
    </div>
  ) : null;
};

export default MemberInfoModal;
