import React from "react";

import PageHeadline from "../components/global/PageHeadline";
import FocusDomains from "../components/focus/FocusDomains";
import CTA from "../components/global/CTA";

const OurFocusScreen = () => {
  return (
    <div className="container pt-[140px]">
      <PageHeadline
        headline="Our Focus"
        description={[
          "While Forager International specializes in products and services that support partners in the tourism, arts, culture, and heritage (TACH) sectors, our focus extends across a variety of industries. As we grow the Forager Partnership Program, we consult with partners to identify areas where they need assistance, which informs the development of new Forager brands. Below is a comprehensive look at the industries where Forager International has developed its brands so far. Each of these areas is developed with the potential to benefit our partners and the community at large.",
        ]}
      />
      <FocusDomains className="py-[100px] lg:py-[140px]" />
      <CTA
        cta={{
          description:
            "Explore the diverse range of Forager brands within each industry focus area to discover how we can empower your organization and drive positive change within the community.",
          btn: { text: "Get In Touch", link: "/contact" },
        }}
      />
    </div>
  );
};

export default OurFocusScreen;
