import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createContactMail } from "../../actions/contactMailActions";

import {
  CONTACTMAIL_CREATE_FAIL,
  CONTACTMAIL_CREATE_RESET,
} from "../../constants/contactMailConstants";

import { validateEmail } from "../../constants/helpers";

import Toast from "../../components/global/Toast";

const ContactForm = ({ defaultReason }) => {
  const dispatch = useDispatch();

  const contactMailCreate = useSelector((state) => state.contactMailCreate);
  const { loading, error, success } = contactMailCreate;

  const [name, setName] = useState(``);
  const [email, setEmail] = useState(``);
  const [subject, setSubject] = useState(``);
  const [reason, setReason] = useState(``);
  const [message, setMessage] = useState(``);

  const nameHandler = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const subjectHandler = (e) => {
    e.preventDefault();
    setSubject(e.target.value);
  };

  const reasonHandler = (e) => {
    e.preventDefault();
    setReason(e.target.value);
  };

  useEffect(() => {
    if (defaultReason) setReason(defaultReason);
  }, [defaultReason]);

  const messageHandler = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    try {
      if (
        !name?.length ||
        !email?.length ||
        !subject?.length ||
        !reason?.length ||
        !message?.length
      ) {
        throw new Error("Fields marked with an * are required!");
      }

      validateEmail(email);

      const contactMail = {
        name,
        email,
        subject,
        reason,
        message,
      };

      dispatch(createContactMail(contactMail));
    } catch (error) {
      dispatch({ type: CONTACTMAIL_CREATE_FAIL, payload: error.message });
    }
  };

  useEffect(() => {
    if (error || success) {
      if (success) {
        setName(``);
        setEmail(``);
        setSubject(``);
        setReason(``);
        setMessage(``);
      }

      setTimeout(() => {
        dispatch({ type: CONTACTMAIL_CREATE_RESET });
      }, 5000);
    }
  }, [error, success, dispatch]);

  return (
    <>
      {loading ? <Toast loading /> : <></>}
      {error && <Toast error body={error} />}
      {success && <Toast success body={"Mail sent successfully. Thank you!"} />}
      <form onSubmit={submitHandler} className="grid gap-5">
        {defaultReason ? (
          <h1>
            {defaultReason === "Partnership Enquiry" ? "Become A Partner" : ""}
          </h1>
        ) : null}
        <p>Fields marked with an * are required</p>
        <div
          className={`flex flex-col ${
            defaultReason ? "lg:flex-row" : ""
          } gap-5`}
        >
          <div className="grow">
            <p className="font-bold mb-1">
              Name <span className="text-red-900">*</span>
            </p>
            <input
              type="text"
              value={name}
              onChange={nameHandler}
              className="border-[1px] bg-gray-ffs border-gray-border-ffs w-full rounded-lg py-2 px-4 focus:outline-none"
              required
            />
          </div>
          <div className="grow">
            <p className="font-bold mb-1">
              Email <span className="text-red-900">*</span>
            </p>
            <input
              type="email"
              value={email}
              onChange={emailHandler}
              className="border-[1px] bg-gray-ffs border-gray-border-ffs w-full rounded-lg py-2 px-4 focus:outline-none"
              required
            />
          </div>
        </div>
        <div>
          <p className="font-bold mb-1">
            Subject <span className="text-red-900">*</span>
          </p>
          <input
            type="text"
            value={subject}
            onChange={subjectHandler}
            className="border-[1px] bg-gray-ffs border-gray-border-ffs w-full rounded-lg py-2 px-4 focus:outline-none"
            required
          />
        </div>
        {!defaultReason ? (
          <div>
            <p className="font-bold mb-1">
              Reason for message <span className="text-red-900">*</span>
            </p>
            <div className="border-[1px] bg-gray-ffs border-gray-border-ffs w-full rounded-lg focus:outline-none cursor-pointer relative">
              <select
                onChange={reasonHandler}
                className="w-full bg-gray-ffs cursor-pointer py-2 px-4 rounded-lg"
                value={reason}
              >
                <option value="hide" className="w-full cursor-pointer">
                  -- Please Select One --
                </option>
                {[
                  "Media Enquiry",
                  "Service Enquiry",
                  "Partnership Enquiry",
                  "Consultation & Advisory",
                  "Technical Support",
                  "Other",
                ].map((ele, index) => (
                  <option
                    key={index}
                    value={ele}
                    className="w-full cursor-pointer"
                  >
                    {ele}
                  </option>
                ))}
              </select>
              <div className="h-full aspect-square absolute right-0 top-0 flex items-center justify-center">
                <i
                  className="iconify text-4xl"
                  data-icon="ic:round-arrow-drop-down"
                />
              </div>
            </div>
          </div>
        ) : null}
        <div>
          <p className="font-bold mb-1">
            Message <span className="text-red-900">*</span>
          </p>
          <textarea
            onChange={messageHandler}
            className="border-[1px] bg-gray-ffs border-gray-border-ffs w-full rounded-lg py-2 px-4 focus:outline-none h-32"
            value={message}
            maxLength="360"
          />
        </div>
        <button
          className="bg-navy hover:bg-denim rounded-full py-3 text-white font-bold lg:w-fit lg:px-9 with-transition"
          onClick={submitHandler}
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default ContactForm;
