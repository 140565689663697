import React from "react";

import { focusDomains } from "../../data/data";

import DomainCard from "./DomainCard";

const FocusDomains = ({ className }) => {
  return (
    <div className={`flex flex-col gap-16 ${className || ""}`}>
      {focusDomains.map((domain, domainIndex) => (
        <DomainCard key={domainIndex} domain={domain} />
      ))}
    </div>
  );
};

export default FocusDomains;
