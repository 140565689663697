import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import whiteLogo from "../../assets/logos/logo-white.svg";
import navyLogo from "../../assets/logos/logo-navy.svg";

function NavegationDesktop({
  links,
  styleNav,
  logoWhite,
  styleMenu,
  userInfo = null,
}) {
  const location = useLocation();

  const [display, setDisplay] = useState(false);

  useEffect(() => {
    document.addEventListener(
      "scroll",
      () => setDisplay(window.pageYOffset ? true : false),
      true,
    );
  }, [display]);

  return (
    <header
      className={`fixed top-0 w-full mb-8 ${
        display ? (logoWhite ? "bg-navy" : "bg-grey") : ""
      } z-[100] with-transition`}
    >
      <div className="container">
        <div className={`w-full z-10 ${styleNav || ""}`}>
          <div className="py-4 flex justify-between items-center w-full">
            <NavLink to="/">
              <img
                src={logoWhite ? whiteLogo : navyLogo}
                alt="Forager International"
                className="w-32"
              />
            </NavLink>
            <nav
              className={`font-bold grid place-items-center ${
                styleMenu || ""
              } text-${logoWhite ? "white" : "navy"}`}
            >
              <ul className="flex gap-10 h-full items-center">
                {links
                  ?.filter((link) => link.inHeader)
                  .map((link, linkIndex) => (
                    <li
                      className={`menu-item ${
                        link.route === location?.pathname
                          ? "selected-menu-item"
                          : ""
                      }`}
                      key={linkIndex}
                    >
                      {link.children ? (
                        <>
                          <div className={`dropdown hover:text-cyan`}>
                            <label
                              id="about-group"
                              tabIndex={0}
                              htmlFor="division-desktop"
                              className="cursor-pointer"
                            >
                              <span
                                className={`flex items-center gap-1 pl-4 py-1 with-transition`}
                              >
                                <h6 className="relative">
                                  <p
                                    className={`with-transition menu-item__line h-0.5 w-2/3 mx-auto lg:mx-0 rounded-full absolute -top-[3px] left-0`}
                                  />
                                  {link.title}
                                </h6>
                                <span className="rotate-0 peer-checked:rotate-180">
                                  <i
                                    className="iconify text-3xl"
                                    data-icon="tabler:chevron-down"
                                  ></i>
                                </span>
                              </span>
                            </label>
                            <ul
                              tabIndex={0}
                              className="menu menu-compact dropdown-content shadow w-44 rounded-lg"
                              id="division-desktop"
                            >
                              {link.children.map((child, childIndex) => (
                                <li
                                  className="py-2 bg-white hover:bg-navy text-navy hover:text-white with-transition"
                                  key={childIndex}
                                >
                                  <NavLink
                                    to={`/${child
                                      .toLowerCase()
                                      .split(" ")
                                      .join("-")}`}
                                    className="flex gap-1 capitalize text-base font-normal"
                                  >
                                    {child}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                      ) : (
                        <>
                          <p
                            className={`with-transition menu-item__line h-0.5 w-1/2 mx-auto lg:mx-0 rounded-full`}
                          />
                          <NavLink
                            to={link.route}
                            className="menu-item__link with-transition"
                          >
                            {link.title}
                          </NavLink>
                        </>
                      )}
                    </li>
                  ))}
                {/* <li>
                  <NavLink
                    to={
                      !userInfo
                        ? "/login"
                        : userInfo.role === "admin"
                        ? "/admin"
                        : `/${userInfo.role}/profile/${userInfo.slug}`
                    }
                    className={`w-fit px-8 py-2 border-2 rounded-full ${
                      logoWhite
                        ? "border-white bg-white hover:bg-navy text-navy hover:text-white"
                        : "border-navy bg-navy hover:bg-white text-white hover:text-navy"
                    } with-transition`}
                  >
                    {userInfo ? "Dashboard" : "Login"}
                  </NavLink>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default NavegationDesktop;
