import React from "react";
import { Link, useLocation } from "react-router-dom";

import { slugify } from "../../constants/helpers";

const FooterNavigationMenu = ({ links, userInfo = null }) => {
  const location = useLocation();

  return (
    <div className="flex flex-col gap-5 md:mx-auto capitalize text-white">
      <h2 className="text-white text-2xl font-bold">Navigation</h2>
      <ul className="flex flex-col gap-4">
        {links
          ?.filter(
            (link) =>
              link.inFooter && !location.pathname.includes(slugify(link.route)),
          )
          ?.map((ele, index) => (
            <li key={index}>
              {ele.title === "About" ? (
                <ul className="flex flex-col gap-4">
                  {ele.children
                    .filter((sub) => !location.pathname.includes(slugify(sub)))
                    .map((sub, subIndex) => (
                      <li
                        key={subIndex}
                        className="hover:text-cyan with-transition w-fit"
                      >
                        <Link
                          to={`/${slugify(sub)}`}
                          className="hover:text-cyan with-transition w-fit"
                        >
                          {sub}
                        </Link>
                      </li>
                    ))}
                </ul>
              ) : (
                <Link
                  to={ele.title === "Home" ? "/" : ele.route}
                  className="hover:text-cyan with-transition w-fit"
                >
                  {ele.title}
                </Link>
              )}
            </li>
          ))}
        {/* <li className="hover:text-cyan with-transition w-fit">
          <Link
            to={
              !userInfo
                ? "/login"
                : userInfo.role === "admin"
                ? "/admin"
                : `/${userInfo.role}/profile/${userInfo.slug}`
            }
          >
            {userInfo ? "Dashboard" : "Login"}
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default FooterNavigationMenu;
