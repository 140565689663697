import React from "react";
import { Link } from "react-router-dom";

const Card = ({ className, btnClassName, content }) => {
  return (
    <div
      className={`lg:w-[75%] mx-auto flex flex-col lg:flex-row gap-8 lg:gap-16 justify-center items-center ${
        className || ""
      }`}
    >
      <img
        className={`w-full lg:w-[30%]`}
        src={require(`../../assets/img/home/vision.png`)}
        alt="vision"
      />
      <div className="grow flex flex-col gap-8">
        <p>{content.description}</p>
        {content?.cta ? (
          <Link
            to={content?.cta?.to}
            className={`flex justify-center items-center text-center px-8 py-2 border-2 rounded-full hover:bg-white text-white border-navy bg-navy hover:text-navy font-semibold with-transition ${
              btnClassName || ""
            }`}
          >
            {content?.cta?.text}
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default Card;
