import React from "react";

import PageHeadline from "../global/PageHeadline";
import MemberCard from "./MemberCard";

const Leadership = ({ team, headline, className, onClick }) => {
  return (
    <PageHeadline headline={headline} noBar className={className}>
      <div className="flex flex-col lg:flex-row flex-wrap pt-[25px] gap-8 lg:gap-0">
        {team.map((member, memberIndex) => (
          <MemberCard key={memberIndex} member={member} onClick={onClick} />
        ))}
      </div>
    </PageHeadline>
  );
};

export default Leadership;
