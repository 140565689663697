import React from "react";

const PageHeadline = ({
  headline,
  noBar,
  subheading,
  description,
  descriptionClassName,
  className,
  headingClassNames,
  headlineClassNames,
  children,
}) => {
  return (
    <div className={className || ""}>
      {headline?.length ? (
        <div
          className={`flex flex-col gap-2 items-center justify-center mx-auto w-fit ${
            headingClassNames || ""
          }`}
        >
          {!noBar ? (
            <span className="h-1 mx-auto w-3/4 rounded-full bg-navy block mb-1" />
          ) : null}
          <h1
            className={`capitalize text-navy ${
              headlineClassNames || ""
            }`}
          >
            {headline}
          </h1>
        </div>
      ) : null}
      {subheading?.length ? (
        <h4 className="mt-4 capitalize text-center text-navy">{subheading}</h4>
      ) : null}
      {children}
      {description?.length ? (
        <div
          className={`my-8 md:text-center max-w-[971px] mx-auto flex flex-col gap-6 ${
            descriptionClassName || ""
          }`}
        >
          {description.map((pharagraph, pharagraphIndex) => (
            <p
              key={pharagraphIndex}
              className={
                pharagraph === "― Richard Branson"
                  ? "font-semibold text-navy text-center"
                  : ""
              }
            >
              {pharagraph}
            </p>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default PageHeadline;
