import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useBreakpoints from "../../hooks/useBreakpoints";

import { links } from "../../data/data";

import NavegationDesktop from "./NavegationDesktop";
import AppDrawer from "./AppDrawer";

const AppHeader = ({ userInfo = null }) => {
  const { lg } = useBreakpoints();

  const location = useLocation();
  const [logoWhite, setLogoWhite] = useState(null);

  useEffect(() => {
    if (["/", "/not-found"].includes(location.pathname)) {
      setLogoWhite({});
    } else {
      setLogoWhite(null);
    }
  }, [location]);

  return lg ? (
    <NavegationDesktop
      links={links}
      logoWhite={logoWhite}
      userInfo={userInfo}
    />
  ) : (
    <AppDrawer links={links} logoWhite={logoWhite} userInfo={userInfo} />
  );
};

export default AppHeader;
