import React from "react";

import GlobalReachVisual from "../../assets/img/home/our-global-reach.svg";

import PageHeadline from "../global/PageHeadline";

const GlobalReach = () => {
  return (
    <div>
      <PageHeadline
        headline="Our Global Reach"
        noBar
        className="lg:hidden mb-6"
        headingClassNames="mx-0"
      />
      <div className="flex flex-col lg:flex-row gap-8 lg:gap-16 justify-center items-center">
        <div className="flex flex-col gap-8 items-center lg:items-start">
          <h1 className="hidden lg:block ">Our Global Reach</h1>
          <p>
            We partner with organizations around the world to provide support to
            projects, build capacity, and search for funding. Whether our
            businesses work with small local organizations or national
            institutions, the approach always values professional quality with a
            personal touch. We are all stronger by working together. If we are
            talking about the Forager International business network or the
            Forager Partnership Network, the sentiment is the same.
          </p>
          <div className="flex flex-col w-full">
            <h3 className="text-navy">Global HQ</h3>
            <p>Gibsons, Canada</p>
          </div>
        </div>
        <img
          src={GlobalReachVisual}
          alt="our-global-reach"
          className="-order-1 lg:order-2"
        />
      </div>
    </div>
  );
};

export default GlobalReach;
