import React from "react";

const BrandCard = ({ brand }) => {
  return brand ? (
    <div className="w-full lg:w-[80%] mx-auto flex flex-col lg:flex-row gap-8 justify-center items-center">
      <img
        className="w-full lg:w-[40%] grow"
        src={require(`../../assets/img/brands/${brand.slug}.svg`)}
        alt={brand.heading}
      />
      <div className="flex flex-col gap-8 items-center lg:items-start lg:w-[60%]">
        <img
          src={require(`../../assets/logos/brands-nomargin/${brand.slug}.svg`)}
          alt={`brand-${brand.heading}`}
          className="h-[60px]"
        />
        {brand.description.map((pharagraph, pharagraphIndex) => (
          <p className="lg:grow" key={pharagraphIndex}>
            {pharagraph}
          </p>
        ))}
      </div>
    </div>
  ) : null;
};

export default BrandCard;
