import React from "react";

import { contact } from "../data/data";

import PageHeadline from "../components/global/PageHeadline";
import SocialLinks from "../components/global/SocialLinks";
import ContactForm from "../components/contact/ContactForm";

const ContactScreen = () => {
  return (
    <div className="container py-[140px]">
      <PageHeadline headline="Contact Forager" />
      <div className="grid lg:grid-cols-2 gap-5 lg:gap-10 xl:gap-20 mt-[100px] lg:mt-[140px]">
        <div>
          <div>
            <h4 className="text-navy">Get In Touch</h4>
            <p className="my-8">
              We are always looking for ways to make new connections and engage
              with our community. If you are interested in learning more about
              Forager International, the brands that we manage, or are
              interested in becoming our newest partner, reach out and one of
              our team will be happy to answer your questions.
            </p>
          </div>
          <div>
            <span className="font-bold text-navy">Main Office</span>
            <p>Gibsons, Canada</p>
          </div>
          <SocialLinks
            className="mt-8"
            iconClassName={"text-navy hover:text-denim"}
          />
          <div className="grid gap-8 mt-12">
            {contact.map((ele, i) => (
              <div key={i} className="flex gap-5">
                <img src={ele.icon} alt="Phone Icon" />
                <div>
                  <span className="h-0.5 w-16 rounded-full bg-navy block mb-1" />
                  <h4 className="text-navy ">{ele.title}</h4>
                  <a
                    className="hover:text-navy with-transition w-fit"
                    href={ele.paragraph}
                  >
                    {ele.paragraph}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <h4 className="text-navy">Send Us A Message</h4>
          <p className="my-8">
            If you have specific inquiries or would like to schedule a
            consultation, please use the form below. Our dedicated team will get
            back to you promptly.
          </p>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default ContactScreen;
