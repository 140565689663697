import axios from "axios";

import {
  CONTACTMAIL_CREATE_REQUEST,
  CONTACTMAIL_CREATE_SUCCESS,
  CONTACTMAIL_CREATE_FAIL,
  CONTACTMAIL_DELETE_REQUEST,
  CONTACTMAIL_DELETE_SUCCESS,
  CONTACTMAIL_DELETE_FAIL,
  CONTACTMAIL_LIST_REQUEST,
  CONTACTMAIL_LIST_SUCCESS,
  CONTACTMAIL_LIST_FAIL,
  CONTACTMAIL_AUTH_REQUEST,
  CONTACTMAIL_AUTH_SUCCESS,
  CONTACTMAIL_AUTH_FAIL,
} from "../constants/contactMailConstants";
import { MAIN_ROUTE } from "../constants/api";

//
//
//
export const createContactMail = (contactMail) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACTMAIL_CREATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${MAIN_ROUTE}/contactmails`,
      contactMail,
      config,
    );

    dispatch({
      type: CONTACTMAIL_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACTMAIL_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//
//
//
export const deleteContactMail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACTMAIL_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.delete(
      `${MAIN_ROUTE}/contactmails/${id}`,
      config,
    );

    dispatch({
      type: CONTACTMAIL_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACTMAIL_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// list all contact mails
export const listContactMails = () => async (dispatch) => {
  try {
    dispatch({
      type: CONTACTMAIL_LIST_REQUEST,
    });

    const { data } = await axios.get(`${MAIN_ROUTE}/contactmails`);

    dispatch({
      type: CONTACTMAIL_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACTMAIL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// authorize admin
export const authContactMails = (password) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACTMAIL_AUTH_REQUEST,
    });

    const { data } = await axios.post(`${MAIN_ROUTE}/contactmails/${password}`);

    dispatch({
      type: CONTACTMAIL_AUTH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACTMAIL_AUTH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
