import React from "react";

import ValueCard from "./ValueCard";

const Values = ({ values, heading, listClassName }) => {
  return values?.length ? (
    <div className="flex flex-col gap-4">
      <h3 className="text-left text-navy">{heading}</h3>
      <div
        className={`flex flex-wrap gap-2 lg:gap-4 justify-center items-center ${
          listClassName || "flex-col lg:flex-row"
        }`}
      >
        {values.map((value, valueIndex) => (
          <ValueCard
            value={value}
            key={valueIndex}
            last={values?.length === valueIndex + 1}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default Values;
