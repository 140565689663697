import {
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_CREATE_RESET,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_RESET,
  USER_LOGOUT,
} from "../constants/userConstants";

//

// create
export const userCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return {
        loading: true,
      };
    case USER_CREATE_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case USER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// login
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case USER_LOGIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_LOGIN_RESET:
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
