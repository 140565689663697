import React, { useState } from "react";

import { communitySectors } from "../data/data";

import ForProfitPartner from "../assets/img/community/for-profit-partners/partner (1).svg";
import bcma from "../assets/img/about/strategic-partners/bcma.svg";
import srcc from "../assets/img/about/strategic-partners/srcc.svg";

import PageHeadline from "../components/global/PageHeadline";
import SectorCard from "../components/community/SectorCard";
import BecomePartner from "../components/global/BecomePartner";
import AppCarousel from "../components/global/AppCarousel";

const OurCommunityScreen = () => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState("More");

  const visibilityHandler = (e) => {
    e.preventDefault();

    if (visible === false) setVisible(true);
    else setVisible(false);

    if (text === "More") setText("Less");
    else setText("More");
  };

  return (
    <div className="py-[140px]">
      <div className="container">
        <PageHeadline
          headline="Our Community"
          description={[
            "At Forager International, we're not just another service provider—we're passionate specialists dedicated to empowering organizations in the sectors of tourism, arts, culture, and heritage (TACH). By focusing our efforts on these interconnected industries, we believe we can better understand and support our partners on their journey toward success.",
          ]}
        ></PageHeadline>
        <div className="flex flex-col lg:flex-row gap-4 justify-center items-center pt-10">
          {communitySectors.map((sector, sectorIndex) => (
            <SectorCard sector={sector} key={sectorIndex} />
          ))}
        </div>
        <BecomePartner className="my-[100px] lg:my-[140px]" />
        <PageHeadline
          headline="Strategic Partners"
          noBar
          className="pt-[100px] lg:pt-[140px] flex flex-col items-start justify-start"
          headingClassNames="mx-0"
        />
        <AppCarousel speed="0.5s">
          <div className="w-fit h-52 rounded-xl grid items-center">
            <img src={bcma} alt="bcma" className="w-[320px] mx-auto" />
          </div>
          <div className="w-fit h-52 rounded-xl grid items-center">
            <img src={srcc} alt="srcc" className="w-[320px] mx-auto" />
          </div>
        </AppCarousel>
      </div>
      <div className="container">
        <PageHeadline
          headline="Non-Profit Partners"
          noBar
          className="pt-[50px] flex flex-col items-start justify-start"
          headingClassNames="mx-0"
        />
      </div>
      {visible ? (
        <div className="container py-[50px]">
          <div className="flex justify-start justify-items-start items-center gap-2 w-full flex-wrap">
            {new Array(40).fill(true).map((ele, index) => (
              <img
                key={index + 1}
                src={require(`../assets/img/community/non-profit-partners/partner (${
                  index + 1
                }).svg`)}
                alt={`partner-${index}`}
                className={`w-[30%] lg:w-[16%]`}
              />
            ))}
          </div>
        </div>
      ) : (
        <AppCarousel isInfinite speed="100s">
          {new Array(40).fill(true).map((brand, brandIndex) => (
            <div
              key={brandIndex}
              className="w-[260px] h-52 rounded-xl grid items-center"
            >
              <img
                src={require(`../assets/img/community/non-profit-partners/partner (${
                  brandIndex + 1
                }).svg`)}
                alt={brandIndex}
                className="w-[320px] mx-auto"
              />
            </div>
          ))}
        </AppCarousel>
      )}
      <div className="container">
        <PageHeadline
          headline="For-Profit Partners"
          noBar
          className="pt-[50px] flex flex-col items-start justify-start"
          headingClassNames="mx-0"
        />
        <div className="w-fit h-52 rounded-xl grid items-center">
          <img
            src={ForProfitPartner}
            alt={"for-profit"}
            className="w-[160px] mx-auto"
          />
        </div>
        <button
          className="bg-navy lg:hover:bg-denim text-white py-3 px-20 w-full lg:w-fit mx-auto block text-center rounded-full font-bold mt-[50px] with-transition capitalize"
          onClick={visibilityHandler}
        >
          view {text}
        </button>
      </div>
    </div>
  );
};

export default OurCommunityScreen;
