import React from "react";
import { Link, NavLink } from "react-router-dom";

import { brands, newsList } from "../data/data";

import HomeHero from "../components/home/HomeHero";
import PageHeadline from "../components/global/PageHeadline";
import ForagerGivesBack from "../components/global/ForagerGivesBack";
import Card from "../components/home/Card";
import AppCarousel from "../components/global/AppCarousel";
import News from "../components/news/News";
import GlobalReach from "../components/home/GlobalReach";

const HomeScreen = () => {
  return (
    <div>
      <HomeHero />
      <div className="py-[100px] lg:py-[140px]">
        <div className="container">
          <PageHeadline
            description={[
              "Forager International was founded on the principles of collaboration and innovation. We have a mission to develop innovative solutions to challenges faced by the TACH sector (tourism, arts, culture, and heritage. Creating relationships with institutions and associations, our goal is to understand the unique needs of this underserved community and work with partners around the world to build capacity for the sector as a whole, preserving and promoting natural and cultural heritage.",
            ]}
            className="mb-[100px] mb-[140px]"
          />
        </div>
        <PageHeadline
          headline="A Growing Network"
          headingClassNames="w-[300px] lg:w-fit text-center"
          className="mb-[100px] lg:mb-[140px]"
        >
          <AppCarousel isInfinite speed="52s" className="flex py-[50px]">
            {brands
              .filter((brand) => brand.slug !== "fnd")
              .map((brand, brandIndex) => (
                <Link
                  key={brandIndex}
                  className="w-[300px] h-52 rounded-xl grid items-center"
                  to={`/brands/${brand.slug}`}
                >
                  <img
                    src={require(`../assets/logos/brands/${brand.slug}.svg`)}
                    alt={brand.slug}
                    className="w-[300px] mx-auto hover:bg-white rounded-xl with-transition"
                  />
                </Link>
              ))}
          </AppCarousel>
          <div className="flex items-center justify-center">
            <NavLink
              to={`/brands/domain/main`}
              className={`w-fit px-8 py-2 border-2 rounded-full bg-navy hover:bg-white text-white border-navy hover:text-navy with-transition font-semibold`}
            >
              Our Business Portfolio
            </NavLink>
          </div>
        </PageHeadline>
        <div className="container">
          <Card
            content={{
              description:
                "We have the vision to create a TACH sector that is more self-reliant and sustainable in its operations and can meet its needs in a rapidly changing world without being solely reliant on government support to achieve success. We believe in the importance of building strong communities through collaboration and innovation, and strong organizations are better equipped to do this.",
              cta: { text: "Our Guiding Force", to: "/our-story" },
            }}
            className="my-[100px] lg:my-[140px]"
            btnClassName="w-full lg:w-fit"
          />
          <ForagerGivesBack
            className="bg-navy"
            handsColor="denim"
            text={[
              "Our mandate is to provide support and community development opportunities within the areas in which we work. That is why Forager International and all businesses under its umbrella are proud to dedicate at least 1% of all revenue to charitable causes in each community where we operate.",
              "To find out more about this program or how to apply for funding, please ",
            ]}
            cta={{ text: "contact us.", to: "/contact" }}
          />
          <News
            heading="Exciting Updates From Across Our Network"
            headlineClassNames="text-left"
            collection={newsList}
            className="mb-[100px] lg:mb-[140px]"
          />
          <GlobalReach />
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
