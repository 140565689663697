import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import whiteLogo from "../../assets/logos/logo-white.svg";
import navyLogo from "../../assets/logos/logo-navy.svg";

const AppDrawer = ({ links, logoWhite, userInfo }) => {
  const [drawerShifting, setDrawerShifting] = useState("-300px");
  const [burgerIndex, setBurgerIndex] = useState(20);
  const [overlayDisplay, setOverlayDisplay] = useState("none");
  const [overlayOpacity, setOverlayOpacity] = useState(0);

  const toggleDrawer = (e) => {
    // e.preventDefault();
    if (drawerShifting === "-300px") {
      setDrawerShifting("0");
    } else {
      setDrawerShifting("-300px");
    }

    if (burgerIndex === 20) {
      setBurgerIndex(0);
    } else {
      setBurgerIndex(20);
    }

    if (overlayOpacity === 0) {
      setTimeout(() => {
        setOverlayOpacity(50);
      }, 250);
    } else {
      setOverlayOpacity(0);
    }

    if (overlayDisplay === "none") {
      setOverlayDisplay("block");
    } else {
      setTimeout(() => {
        setOverlayDisplay("none");
      }, 250);
    }
  };

  const [toggledDropdown, setToggledDropdown] = useState(false);

  const linkDropdownHandler = (e) => {
    e.preventDefault();
    setToggledDropdown(!toggledDropdown);
  };

  return (
    <>
      <div
        className="bg-denim w-full h-full fixed top-0 left-0 z-20 mb-8 with-transition"
        style={{ display: overlayDisplay, opacity: `${overlayOpacity}%` }}
        onClick={toggleDrawer}
      ></div>
      <NavLink to="/">
        <img
          src={logoWhite ? whiteLogo : navyLogo}
          alt="Forager International"
          className="w-[180px] pl-5 pt-7 absolute top-0 left-0 z-50"
        />
      </NavLink>
      <div
        className={`w-12 aspect-square ${
          logoWhite ? "bg-white text-navy" : "bg-navy text-white"
        } fixed right-5 top-5 grid place-content-center rounded-full`}
        style={{ zIndex: burgerIndex }}
        onClick={toggleDrawer}
      >
        <i className="iconify text-4xl" data-icon="majesticons:menu" />
      </div>
      <div
        className="h-full px-6 fixed top-0 w-[300px] with-transition bg-denim z-50"
        style={{ right: drawerShifting }}
      >
        <label className="absolute right-4 top-4" onClick={toggleDrawer}>
          <i
            className="iconify text-2xl text-white absolute right-3 top-4"
            data-icon="heroicons-solid:x"
          />
        </label>
        <ul className="text-white mt-32 w-full capitalize grid gap-8 font-bold">
          {/* <li className="w-full mt-10">
            <NavLink
              to={
                !userInfo
                  ? "/login"
                  : userInfo.role === "admin"
                  ? "/admin"
                  : `/${userInfo.role}/profile/${userInfo.slug}`
              }
              className={`w-full flex justify-center items-center text-center mx-auto px-8 py-2 border-2 rounded-full hover:bg-white text-white ${
                userInfo
                  ? "border-orange bg-orange hover:text-orange"
                  : "border-navy bg-navy hover:text-navy"
              } with-transition`}
              onClick={toggleDrawer}
            >
              {userInfo ? "Dashboard" : "Login"}
            </NavLink>
          </li> */}
          {links
            ?.filter((link) => link.inDrawer)
            .map((link, linkIndex) => (
              <li key={linkIndex} className="relative">
                {link.children ? (
                  <>
                    <div className="relative">
                      <label
                        className="z-10 rounded-l-full font-semibold"
                        onClick={linkDropdownHandler}
                      >
                        <h6 onClick={linkDropdownHandler}>{link.title}</h6>
                      </label>
                      <span
                        className={`absolute top-[1px] left-[64px] ${
                          toggledDropdown ? "-rotate-90" : "rotate-90"
                        } with-transition`}
                        onClick={linkDropdownHandler}
                      >
                        <i
                          className="iconify text-xl"
                          data-icon="ep:arrow-up-bold"
                          data-rotate="90deg"
                        />
                      </span>
                    </div>
                    <nav>
                      <ul
                        className={`text-white flex flex-col gap-4 overflow-hidden ${
                          toggledDropdown ? "h-[150px] pt-4" : "h-0"
                        } with-transition`}
                      >
                        {link.children.map((child, childIndex) => (
                          <li key={childIndex}>
                            <NavLink
                              to={`/${child
                                .toLowerCase()
                                .split(" ")
                                .join("-")}`}
                              className="flex gap-1"
                              onClick={() => {
                                toggleDrawer();
                                linkDropdownHandler();
                              }}
                            >
                              <span className="capitalize font-normal">
                                {child}
                              </span>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </>
                ) : (
                  <NavLink to={link.route} onClick={toggleDrawer}>
                    {link.title}
                  </NavLink>
                )}
              </li>
            ))}
        </ul>
        {/* <img
          // src={require("../../assets/logos/isotipo-full.png")}
          className="w-[120px] mt-32"
          alt=""
        /> */}
      </div>
    </>
  );
};
export default AppDrawer;
