import React from "react";

import SocialLinks from "../global/SocialLinks";

const BlogBody = ({ blog }) => {
  return blog ? (
    <section className="pt-4">
      <div className="lg:grid lg:grid-cols-4 lg:relative xl:max-w-full xl:mx-auto gap-8 lg:gap-16">
        {/*  */}
        <div className="grid gap-8 col-span-5 xl:pr-8 w-full lg:w-[90%] mx-auto">
          {blog.description?.length ? (
            <div className="flex flex-col gap-8 mb-8">
              {blog.description.map((paragraph, paragraphIndex) => (
                <p
                  className={`${paragraph.startsWith("-") ? "pl-8" : ""}`}
                  key={paragraphIndex}
                >
                  {paragraph}
                </p>
              ))}
            </div>
          ) : (
            <></>
          )}
          {blog.about?.length ? (
            <div className="flex flex-col gap-8 pt-4">
              {blog.about.map((entity, entityIndex) => (
                <div key={entityIndex} className="flex flex-col gap-4">
                  <h4 className="font-bold">{entity.entity}</h4>
                  <p>{entity.description}</p>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        {/*  */}
        <div className="grid md:hidden place-items-center mt-10">
          <span className="w-full flex md:block h-0.5 rounded-full bg-denim-ffs opacity-20 mb-8 border-y-2 border-y-denim-1/4 pb-4" />
          <SocialLinks
            className="mt-5"
            iconClassName={"text-navy hover:text-denim"}
          />
        </div>
      </div>
      <span className="hidden md:flex h-0.5 rounded-full bg-denim-ffs opacity-20 mt-10" />
      <div className="hidden md:flex justify-center border-y-2 border-y-denim-1/4 pb-4">
        <SocialLinks
          className="mt-5"
          iconClassName={"text-navy hover:text-denim"}
        />
      </div>
    </section>
  ) : null;
};

export default BlogBody;
