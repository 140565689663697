import React from "react";

import PageHeadline from "../components/global/PageHeadline";

const CareersScreen = () => {
  return (
    <div className="container py-[140px]">
      <PageHeadline
        headline="Working At Forager"
        description={[
          "All Forager businesses strive for inclusive and representative teams. We actively search for ways to engage with underrepresented communities and find skills-sharing opportunities to allow candidates to thrive. We pride ourselves on appreciating the experience of candidates, but also seeing the potential in candidates.",
          "All of our roles are fully or partially remote because we believe a workspace that allows teams to work from wherever inspires the same exploration that we actively try to create through the projects we develop with partners.",
        ]}
      />
      <div className="py-[100px]">
        <h1 className="capitalize text-center text-navy">
          Current Opportunities
        </h1>
        <div className="mt-[50px] w-full lg:w-[50%] mx-auto h-auto rounded-xl bg-white p-8 flex items-center justify-center with-transition font-bold">
          No positions available
        </div>
      </div>
    </div>
  );
};

export default CareersScreen;
