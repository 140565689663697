import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ErrorScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/not-found") {
      navigate(`/not-found`);
    }

    // eslint-disable-next-line
  }, [navigate]);

  return (
    <section className="relative bg-cover bg-not-found-page h-screen">
      <div className="container mx-auto">
        <div className="px-4 text-center md:text-left pt-44 md:pt-60 flex flex-col gap-6">
          <h1 className="font-bold text-4xl lg:text-6xl text-white">
            Page Not Found (404)
          </h1>
          <p className="text-white">
            It looks like this page has moved somewhere else. come back to the
            homepage
          </p>
          <Link
            to="/"
            className="mx-auto md:mx-0 grid place-items-center font-medium w-48 h-12 rounded-full bg-white hover:bg-denim text-denim hover:text-white font-semibold with-transition"
          >
            back to home
          </Link>
        </div>
      </div>
      <img
        src={require("../assets/img/404/rocket.png")}
        alt=""
        className="absolute -bottom-20 md:top-1/4 lg:top-0 md:right-0 w-4/5 -rotate-180 md:rotate-0 md:w-2/5 pt-32 pr-0"
      />
    </section>
  );
};

export default ErrorScreen;
