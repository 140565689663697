import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import { redirect } from "./constants/helpers";

import ScrollToTop from "./components/global/ScrollToTop";
import SEO from "./components/global/SEO";

import AppHeader from "./components/global/AppHeader";
import AppFooter from "./components/global/AppFooter";

import HomeScreen from "./screens/HomeScreen";
import OurCommunityScreen from "./screens/OurCommunityScreen";
import OurFocusScreen from "./screens/OurFocusScreen";
import OurStoryScreen from "./screens/OurStoryScreen";
import OurTeamScreen from "./screens/OurTeamScreen";
import BrandsScreen from "./screens/BrandsScreen";
import SingleBrandScreen from "./screens/SingleBrandScreen";
import NewsroomScreen from "./screens/NewsroomScreen";
import SingleNewsroomScreen from "./screens/SingleNewsroomScreen";
import CareersScreen from "./screens/CareersScreen";
import ContactScreen from "./screens/ContactScreen";
import ErrorScreen from "./screens/ErrorScreen";
// import AdminScreen from "./screens/AdminScreen";
// import RegisterScreen from "./screens/RegisterScreen";

const App = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    redirect();
  });

  return (
    <Router>
      <SEO />
      <ScrollToTop />
      <AppHeader userInfo={userInfo} />
      <Routes>
        <Route path="/" element={<HomeScreen />} exact />
        <Route path="/our-story" element={<OurStoryScreen />} exact />
        <Route path="/our-community" element={<OurCommunityScreen />} exact />
        <Route path="/our-focus" element={<OurFocusScreen />} exact />
        <Route path="/our-team" element={<OurTeamScreen />} exact />
        <Route path="/brands/domain/:domain" element={<BrandsScreen />} exact />
        <Route path="/brands/:slug" element={<SingleBrandScreen />} exact />
        <Route path="/newsroom" element={<NewsroomScreen />} exact />
        <Route
          path="/newsroom/:slug"
          element={<SingleNewsroomScreen />}
          exact
        />
        <Route path="/careers" element={<CareersScreen />} exact />
        <Route path="/contact" element={<ContactScreen />} exact />

        {/* <Route path="/admin" element={<AdminScreen />} exact /> */}
        {/* <Route path="/register" element={<RegisterScreen />} exact /> */}
        {/*  */}
        <Route path="/not-found" element={<ErrorScreen />} exact />
        <Route path="*" element={<ErrorScreen />} exact />
      </Routes>
      <AppFooter userInfo={userInfo} />
    </Router>
  );
};

export default App;
