import React, { useEffect, useState } from "react";

const BlogReadingTime = ({ blog, className }) => {
  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    if (blog) {
      setWordCount(
        Math.ceil(
          Number(
            blog.heading.split(" ").length +
              blog.subheading.split(" ").length +
              blog.description
                .map((ele) => ele.split(" ").length)
                .reduce((ele, acc) => ele + acc, 0) +
              blog.about
                .map(
                  (ele) =>
                    ele.entity.split(" ").length +
                    ele.description.split(" ").length,
                )
                .reduce((ele, acc) => ele + acc, 0),
          ) / 200,
        ) || 0,
      );
    }
  }, [blog]);

  return wordCount ? (
    <p className={`${className}`}>{`${wordCount} minute read!`}</p>
  ) : null;
};

export default BlogReadingTime;
