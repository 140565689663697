import React from "react";

const Copyrights = () => {
  return (
    <div className="md:col-span-2 pb-4">
      <hr className="md:w-[70%] w-full mx-auto mb-2" />
      <p className="text-xs text-center">
        © Copyright {new Date().getFullYear()}, Forager Group Holdings Ltd.{" "}
        <span className="text-xs block md:inline-block mt-0.5">
          All rights reserved.
        </span>
      </p>
    </div>
  );
};

export default Copyrights;
