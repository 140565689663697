import React from "react";

import Mission from "../../assets/img/story/mission.png";

const MissionAndVision = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-16">
      <div className="flex flex-col gap-8 grow">
        <h1>Mission & Values</h1>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <h3 className="text-navy">Mission</h3>
            <p>
              We create innovative solutions through collaborative alliances
              with institutions and regional associations to nurture our
              partners by equipping them with the tools and support needed to
              adapt and thrive in the ever-evolving modern landscape. We are
              committed to understanding the unique needs of our stakeholders
              and fostering sustainable growth.
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <h3 className="text-navy">Vision</h3>
            <p>
              Cultivate a TACH sector that is resilient, self-sustaining, and
              adaptable to the demands of a rapidly changing world. By fostering
              a culture of innovation and collaboration, we aim to redefine the
              future of tourism, arts, culture, and heritage globally.
            </p>
          </div>
        </div>
      </div>
      <img
        src={Mission}
        alt="mission-and-values"
        className="w-full lg:w-[40%] order-2 lg:-order-1"
      />
    </div>
  );
};

export default MissionAndVision;
