import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import descriptions from "../../data/seo";

const SEO = () => {
  const location = useLocation();

  const [content, setContent] = useState(null);

  useEffect(() => {
    setContent(
      descriptions.find((desc) => desc.pathname === location?.pathname) ||
        []
          .concat(
            ...descriptions
              .filter((desc) => desc.children)
              .map((desc) => desc.children),
          )
          .find((desc) => desc.pathname === location?.pathname) ||
        []
          .concat(
            ...descriptions
              .filter((desc) => desc.categories)
              .map((desc) => desc.categories),
          )
          .find((desc) => desc.pathname === location?.pathname),
    );
  }, [location]);

  return content ? (
    <Helmet>
      <title>{content.title}</title>
      {content.content?.map((excerpt, excerptIndex) => (
        <meta key={excerptIndex} name="description" content={excerpt} />
      ))}
      <meta name="keywords" content={`${content.keywords?.join(", ")}`} />
    </Helmet>
  ) : null;
};

export default SEO;
