import React from "react";

import { slugify } from "../../constants/helpers";

const ValueCard = ({ value, last }) => {
  return (
    <div
      className={`bg-navy p-6 lg:p-8 flex gap-8 justify-center items-center text-white w-full lg:w-[45%] ${
        last ? "grow-0" : "grow"
      } rounded-3xl`}
    >
      <img
        src={require(`../../assets/img/story/${
          value.type || "value"
        }s/${slugify(value.title)}.${value.format || "svg"}`)}
        alt=""
      />
      <div className="flex flex-col gap-2 grow">
        <h4>{value.title}</h4>
        <p>{value.description}</p>
      </div>
    </div>
  );
};

export default ValueCard;
