import React from "react";
import { Link } from "react-router-dom";

const HomeHero = () => {
  return (
    <section>
      <div>
        <div className="app-home-hero relative overflow-hidden bg-cover bg-no-repeat with-curved-bottom">
          <div className="container mx-auto pb-52 lg:pb-32 text-white">
            <div className="md:hidden">
              <img
                src={require("../../assets/img/home/mobile/planet-1.png")}
                alt=""
                className="absolute left-6 top-16 w-32"
              />
              <img
                src={require("../../assets/img/home/mobile/circle-1.png")}
                alt=""
                className="absolute -right-20 top-0 w-32 -translate-y-5"
              />
              <img
                src={require("../../assets/img/home/mobile/planet-rocket.png")}
                alt=""
                className="absolute -bottom-40 -right-32 w-64 scale-x-[-1] rotate-[-115deg]"
              />
            </div>
            <div className="hidden md:block">
              <img
                src={require("../../assets/img/home/desktop/planet-1.png")}
                alt=""
                className="absolute -left-10 -top-[87%] xl:-top-[73%] w-[52rem]"
              />
              <img
                src={require("../../assets/img/home/desktop/planet-2.png")}
                alt=""
                className="absolute left-1/2 -translate-x-1/2 top-24 w-20"
              />
              <img
                src={require("../../assets/img/home/desktop/circle-1.png")}
                alt=""
                className="absolute -right-80 -translate-x-1/2 -top-80 w-[32rem]"
              />
              <img
                src={require("../../assets/img/home/desktop/planet-rocket.png")}
                alt=""
                className="absolute -bottom-96 xl:-bottom-80 -right-52 xl:-right-32 w-[48rem]"
              />
            </div>
            <div className="w-fit">
              <div className="max-w-[16rem] lg:max-w-[52.5rem] flex flex-col lg:items-start my-16">
                <h2 className="mt-16 md:mt-40 text-4xl lg:text-6xl leading-[3.1rem] lg:leading-[4.6rem] mb-8 lg:mb-12 z-50 text-white capitalize">
                  A Network Of Businesses Innovating Solutions For <br />
                  Our Partners
                </h2>
                <Link
                  to="/our-community"
                  className="w-40 h-12 mb-1 lg:mb-16 font-medium bg-white hover:bg-denim hover:text-white rounded-full text-denim grid place-items-center font-semibold with-transition font-semibold"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
