import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { brands } from "../data/data";

import fndVisual from "../assets/logos/brands-nomargin/fnd.svg";

import BrandCard from "../components/brands/BrandCard";
import ChildBrandCard from "../components/brands/ChildBrandCard";
import DomainCard from "../components/focus/DomainCard";
import ForagerGivesBack from "../components/global/ForagerGivesBack";
import CTA from "../components/global/CTA";

const SingleBrandScreen = () => {
  const navigate = useNavigate();

  const { slug } = useParams();

  const [brand, setBrand] = useState(null);

  useEffect(() => {
    if (slug) {
      if (brands.find((brand) => brand.slug === slug))
        setBrand(brands.find((brand) => brand.slug === slug));
      else navigate("/");
    }
  }, [slug, navigate]);

  return brand ? (
    <div className="container pt-[140px]">
      <div className={`flex flex-col ${slug === "fnd" ? "" : `gap-[${brand.children?.length ? "100" : "50"}px]`}`}>
        {slug === "fnd" ? (
          <div className="flex flex-col gap-[100px] lg:gap-[140px]">
            <div>
              <div className="flex flex-col gap-2 items-center justify-center mx-auto w-fit">
                <img
                  src={fndVisual}
                  alt="forager-foundation"
                  className="w-[300px]"
                />
              </div>
              <div className="my-8 md:text-center max-w-[971px] mx-auto">
                {brand.description.map((pharagraph, pharagraphIndex) => (
                  <p className="lg:grow" key={pharagraphIndex}>
                    {pharagraph}
                  </p>
                ))}
              </div>
            </div>
            <DomainCard domain={brand.domain} noLink />
            <ForagerGivesBack handsColor="green" text={brand.givesBack} />
          </div>
        ) : (
          <>
            <BrandCard brand={brand} />
            <div className="pb-[100px]">
              {brand.children?.length ? (
                <div className="flex flex-col lg:flex-row gap-4 justify-center items-center">
                  {brand.children.map((child, childIndex) => (
                    <ChildBrandCard key={childIndex} brand={child} />
                  ))}
                </div>
              ) : null}
            </div>
          </>
        )}
        <CTA cta={brand.cta} />
      </div>
    </div>
  ) : null;
};

export default SingleBrandScreen;
